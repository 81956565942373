import { Drawer, Form } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DrawerHeader from '../../../components/DrawerHeader/DrawerHeader'
import UpdateElasticButton from '../assets-header/update-elastic-button/UpdateElasticButton'
import * as assetsSlice from '../assetsSlice'
import { Family } from '../assetsSlice'
import FamilyForm from './family-form/FamilyForm'
import FamilyRemoveModal from './family-remove-modal/FamilyRemoveModal'

const FamilyDrawer: FunctionComponent = () => {
  const [form] = Form.useForm()
  const drawer = useSelector(assetsSlice.selectDrawer)
  const family = drawer.data as Family
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const handleFormSubmitSuccess = () => {
    setLoading(false)
    handleCloseDrawer()
  }

  const handleCloseDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: 'family', data: null }))
  }

  const handleSubmit = async () => {
    await form.submit()
  }

  return (
    <>
      <Drawer
        destroyOnClose
        width={720}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() =>
          dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: 'family' }))
        }
        visible={drawer.type === 'family' && drawer.visible}
      >
        <DrawerHeader
          showSaveButton
          isSaving={loading}
          title={family?.hash ? 'Modify Family' : 'New Family'}
          onClickCancel={() => handleCloseDrawer()}
          onClickSave={handleSubmit}
          showDeleteButton={!!family?.hash}
          onClickDelete={() => setIsDeleteModalVisible(true)}
          extraContent={<UpdateElasticButton />}
        />
        <FamilyForm
          form={form}
          family={family}
          onSubmit={() => setLoading(true)}
          onSubmitSuccess={() => handleFormSubmitSuccess()}
          onSubmitError={() => setLoading(false)}
        />
      </Drawer>
      {isDeleteModalVisible && (
        <FamilyRemoveModal onCancel={() => setIsDeleteModalVisible(false)} family={family} />
      )}
    </>
  )
}

export default FamilyDrawer
