import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import AssetsPage from '../../pages/assets/AssetsPage'
import PreferencesPage from '../../pages/preferences/PreferencesPage'
import SigninPage from '../../pages/signin/Signin'
import TagsPagesPage from '../../pages/tags-pages/TagsPagesPage'
import UserDetailsPage from '../../pages/users/details/UserDetailsPage'
import UsersPage from '../../pages/users/UsersPage'
import * as authSlice from '../auth/authSlice'
import { UserStatus } from '../common-interfaces/User'
import PrivateRoute from './private-route'

export enum Routes {
  SIGNIN = '/signin',
  ASSETS = '/assets',
  USERS = '/users',
  USERS_DETAILS = '/users/details/:userHash',
  PREFERENCES = '/preferences',
  TAGS_PAGES = '/tags-pages',
}

/**
 * On 404 just redirects to /
 */
const AppRouter: FunctionComponent = () => {
  const loggedInUser = useSelector(authSlice.selectLoggedInUser)

  return (
    <Switch>
      <Route path={Routes.SIGNIN} component={SigninPage} />
      <Redirect exact from="/" to={Routes.SIGNIN} />

      {loggedInUser?.status === UserStatus.CUSTOMER_SUPPORT && (
        <Redirect exact from={Routes.ASSETS} to={Routes.USERS} />
      )}

      <PrivateRoute path={Routes.ASSETS} component={AssetsPage} exact />
      <PrivateRoute path={Routes.USERS} component={UsersPage} exact />
      <PrivateRoute path={Routes.USERS_DETAILS} component={UserDetailsPage} />
      <PrivateRoute path={Routes.PREFERENCES} component={PreferencesPage} exact />
      <PrivateRoute path={Routes.TAGS_PAGES} component={TagsPagesPage} exact />

      <Redirect to="/" />
    </Switch>
  )
}

export default AppRouter
