import { Col, Row } from 'antd'
import React, { FunctionComponent } from 'react'
import styles from './DrawerTwoColumns.module.scss'

interface FormTwoColumnsProps {
  leftCol: React.ReactNode
  rightCol: React.ReactNode
}

const DrawerTwoColumns: FunctionComponent<FormTwoColumnsProps> = ({ leftCol, rightCol }) => (
  <Row className={styles.root}>
    <Col className={styles.left} span={12}>
      {leftCol}
    </Col>
    <Col className={styles.right} span={12}>
      {rightCol}
    </Col>
  </Row>
)

export default DrawerTwoColumns
