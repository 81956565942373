import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import api from '../../app/api/api'
import { RootState, AppThunk } from '../../app/store'
import { ProductTypeEnum } from '../assets/assetsSlice'
import { IndexingResult, IndexingStatusEnum } from './ReindexAllElasticButton/types'

interface PreferencesState {
  indexingResult: IndexingResult
  isIndexing: boolean
  isTrainingAiModelsIcon: boolean
  isTrainingAiModelsIllustration: boolean
  errorMessage: string
}

const initialState: PreferencesState = {
  indexingResult: { indexName: '', status: IndexingStatusEnum.NONE, unindexedFamilies: [] },
  isIndexing: false,
  isTrainingAiModelsIcon: false,
  isTrainingAiModelsIllustration: false,
  errorMessage: '',
}

export const preferencesSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setIsIndexing: (state, action: PayloadAction<boolean>) => {
      state.isIndexing = action.payload
    },
    fetchIndexingResultSuccess: (state, action: PayloadAction<IndexingResult>) => {
      state.indexingResult = action.payload
      state.isIndexing = action.payload.status === IndexingStatusEnum.IN_PROGRESS
    },
    fetchIndexingResultFailed: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload
    },
    postAiModelIconTrainRequest: (state) => {
      state.isTrainingAiModelsIcon = true
    },
    postAiModelIllustrationTrainRequest: (state) => {
      state.isTrainingAiModelsIllustration = true
    },
    postAiModelTrainResultSuccess: (state) => {
      state.isTrainingAiModelsIcon = false
      state.isTrainingAiModelsIllustration = false
    },
    postAiModelTrainFailed: (state, action: PayloadAction<string>) => {
      state.isTrainingAiModelsIcon = false
      state.isTrainingAiModelsIllustration = false
      state.errorMessage = action.payload
    },
  },
})

export const { actions } = preferencesSlice

export const reIndexAssets =
  (indexName: string, familiesSlugs?: string[]): AppThunk =>
  async (dispatch) => {
    try {
      await api.post('/v4/open-search/index', {
        indexName,
        familiesSlugs,
      })
      message.info('Assets are being reindexed. It may take several minutes.', 5)
      dispatch(getIndexingResult())
    } catch (error: any) {
      message.error('Reindex was not started, please try again')
    }
  }

export const getIndexingResult = (): AppThunk => async (dispatch) => {
  try {
    const response = await api.get('/v4/open-search/index-result')
    dispatch(actions.fetchIndexingResultSuccess(response.data))
  } catch (error: any) {
    dispatch(actions.fetchIndexingResultFailed(error.message))
  }
}

export const trainAiModel =
  (productType: ProductTypeEnum): AppThunk =>
  async (dispatch) => {
    try {
      if (productType === ProductTypeEnum.ICONS) {
        dispatch(actions.postAiModelIconTrainRequest())
      } else {
        dispatch(actions.postAiModelIllustrationTrainRequest())
      }
      const { data } = await api.post('/v4/ai/model/train', { productType })

      if (data.totalAssetsUpdated > 0) {
        message.info(
          `${data.totalAssetsUpdated} assets were updated in the dataset and a new model training was started.`,
          20
        )
      } else {
        message.info('A new model training was started.', 20)
      }

      dispatch(actions.postAiModelTrainResultSuccess())
    } catch (error: any) {
      dispatch(actions.postAiModelTrainFailed(error.message))
      message.error('The training was not started, please try again')
    }
  }

export const addMissingTag =
  (tag: string): AppThunk =>
  async () => {
    try {
      const response = await api.patch('/v4/ai/auto-tagging/synonyms', {
        tag,
      })
      message.success(response.data.message)
    } catch (error: any) {
      message.error('Error adding tag: ', error.messages.join(', '))
    }
  }

export const selectIndexingResult = (state: RootState) => state.preferences.indexingResult
export const selectIsIndexing = (state: RootState) => state.preferences.isIndexing
export const selectIsTrainingAiIconModel = (state: RootState) =>
  state.preferences.isTrainingAiModelsIcon
export const selectIsTrainingAiIllustrationModel = (state: RootState) =>
  state.preferences.isTrainingAiModelsIllustration

export const { setIsIndexing } = preferencesSlice.actions

export default preferencesSlice.reducer
