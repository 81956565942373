export interface ILicense {
  id?: string
  key?: string
  hash?: string
  activated?: boolean
  expired?: boolean
  userHash: string
  productName?: string
  oneTime?: boolean
  type?: 'plan' | 'legacy'
  expirationDate?: string
  seats: number
  productHash?: string
  bundleHash?: string
  createdAt?: Date
}

export interface IUser {
  id: number
  hash: string
  email: string
  firstname: string
  lastname: string
  stripeId: string | null
  stripeSubscriptionId: string | null
  status: UserStatus
  emailValidated: boolean
  createdAt: Date
  updatedAt: Date
  isLegacy: boolean
  members: Member[]
  memberFrom: Member
  invitationsSent: Invitation[]
  invitationsReceived: Invitation[]
  availableSeats: AvailableSeats
  isBetaTester: boolean
  downloadsLimitEnabled: boolean
  downloadsLimitPeriodResetsOn: Date
}

export enum UserStatus {
  ADMIN = 'admin',
  CUSTOMER_SUPPORT = 'customer support',
  CUSTOMER_MEMBER = 'customer member',
  CONTENT_EDITOR = 'content editor',
  FREE_USER = 'free user',
  CUSTOMER = 'customer',
  TAG_REVIEWER = 'tag reviewer',
}

export const userStatusList = Object.values(UserStatus)
export const userStatusListNonAdmins = userStatusList.filter(
  (status) =>
    status !== UserStatus.ADMIN &&
    status !== UserStatus.CONTENT_EDITOR &&
    status !== UserStatus.CUSTOMER_SUPPORT &&
    status !== UserStatus.TAG_REVIEWER
)
export const userStatusListAdmins = userStatusList.filter(
  (status) =>
    status === UserStatus.ADMIN ||
    status === UserStatus.CONTENT_EDITOR ||
    status === UserStatus.CUSTOMER_SUPPORT ||
    status === UserStatus.TAG_REVIEWER
)

export type Member = {
  email: string
}

export type Invitation = {
  email: string
}

export type AvailableSeats = {
  total: number
  available: number
}
