import { Button, Card, Input, Typography } from 'antd'
import { useDispatch } from 'react-redux'
import React, { FunctionComponent, useState } from 'react'
import { addMissingTag } from '../preferencesSlice'

const { Paragraph } = Typography

const AddMissingTag: FunctionComponent = () => {
  const dispatch = useDispatch()
  const [missingTag, setMissingTag] = useState('')

  return (
    <Card title="Add missing tag">
      <Paragraph>
        Add missing tags to all icons. It finds synonyms for the specified tag, locates icons with
        these synonyms as tags, and updates them with the specified tag.
      </Paragraph>

      <Input
        style={{ width: '250px' }}
        placeholder="Tag"
        onChange={(e) => setMissingTag(e.target.value.toLowerCase())}
      />
      <Button
        title="Click here to reindex all assets on OpenSearch!"
        onClick={() => dispatch(addMissingTag(missingTag))}
      >
        Add missing tag
      </Button>
    </Card>
  )
}

export default AddMissingTag
