import * as firebaseAuth from 'firebase/auth'
import { message } from 'antd'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import api from '../api/api'
import { IUser, userStatusListAdmins } from '../common-interfaces/User'
import { Routes } from '../routes/app-router'
import { AppThunk, RootState } from '../store'
import auth from './firebaseAuthentication'
import { clearAuthenticationTokenFromLocalStorage } from './authentication'

export interface AuthState {
  isValidatingToken: boolean
  isLoggedIn: boolean
  loggedInUser: IUser | null
}

const initialState: AuthState = {
  isValidatingToken: true,
  isLoggedIn: false,
  loggedInUser: null,
}

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signInSuccess: (state, action: PayloadAction<{ user: IUser }>) => {
      state.loggedInUser = action.payload.user
      state.isLoggedIn = true
      state.isValidatingToken = false
    },
    authTokenIsValid: (state, action: PayloadAction<{ user: IUser }>) => {
      state.loggedInUser = action.payload.user
      state.isLoggedIn = true
      state.isValidatingToken = false
    },
    authTokenIsInvalid: (state) => {
      state.loggedInUser = null
      state.isLoggedIn = initialState.isLoggedIn
      state.isValidatingToken = false
    },
  },
})

export const { signInSuccess, authTokenIsValid, authTokenIsInvalid } = authSlice.actions

export const checkAuthorizationToken = (): AppThunk => async (dispatch) => {
  try {
    const { data } = await api.get('/v4/users/me')
    if (userStatusListAdmins.includes(data.status)) {
      dispatch(authTokenIsValid({ user: data }))
    } else {
      dispatch(signOut())
      message.warn('Sign in not authorized.')
    }
  } catch (error: any) {
    // Means that a user is not found
    if (error.response?.status === 404) {
      clearAuthenticationTokenFromLocalStorage()
      // Retry auth again
      dispatch(checkAuthorizationToken())
    }
    dispatch(authTokenIsInvalid())
  }
}

export const signOut = (): AppThunk => async (dispatch) => {
  firebaseAuth.signOut(auth)

  dispatch(authTokenIsInvalid())
  dispatch(push(Routes.SIGNIN))
}

export const selectLoggedInUser = (state: RootState) => state.auth.loggedInUser
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn
export const selectIsValidatingToken = (state: RootState) => state.auth.isValidatingToken

export default authSlice.reducer
