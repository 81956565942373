import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import arrowUp from '../../../assets/arrow-up.svg'
import CursiveText from '../../../components/CursiveText/CursiveText'
import * as assetsSlice from '../assetsSlice'
import styles from './FamiliesHelp.module.scss'

const FamiliesHelp: FunctionComponent = () => {
  const family = useSelector(assetsSlice.selectFamily)

  return (
    <div className={styles.root}>
      {!family && (
        <>
          <div className={styles.left}>
            <img className={styles.invertedArrow} src={arrowUp} alt="Arrow up" />
            <CursiveText text="Start by selecting the family" />
          </div>

          <div className={styles.right}>
            <CursiveText text="or create a new family" />
            <img className={styles.rightArrow} src={arrowUp} alt="Arrow up" />
          </div>
        </>
      )}
    </div>
  )
}

export default FamiliesHelp
