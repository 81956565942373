import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import assetsReducer from '../features/assets/assetsSlice'
import usersReducer from '../features/users/usersSlice'
import preferencesReducer from '../features/preferences/preferencesSlice'

import authReducer from './auth/authSlice'
import { history } from './history'

export const rootReducer = (h: History) =>
  combineReducers({
    router: connectRouter(h),
    assets: assetsReducer,
    users: usersReducer,
    auth: authReducer,
    preferences: preferencesReducer,
  })

// export const history = createBrowserHistory()

export const getStore = (initialState?: any) =>
  configureStore({
    reducer: rootReducer(history),
    preloadedState: initialState,
  })
export const store = getStore()

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
