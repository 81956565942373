import React, { FunctionComponent, PropsWithChildren } from 'react'
import AppNavbar from '../AppNavbar/AppNavbar'

interface LayoutAdminProps extends PropsWithChildren<{}> {}

const LayoutAdmin: FunctionComponent<LayoutAdminProps> = ({ children }) => (
  <>
    <AppNavbar />
    {children}
  </>
)

export default LayoutAdmin
