import { MedicineBoxOutlined } from '@ant-design/icons'
import { Button, Divider, Drawer, Form, Input, message, Switch, Tooltip } from 'antd'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../app/api/apiHook'
import handleError from '../../../app/api/handleError'
import DrawerHeader from '../../../components/DrawerHeader/DrawerHeader'
import DrawerSubtitle from '../../../components/DrawerSubtitle/DrawerSubtitle'
import DrawerTwoColumns from '../../../components/DrawerTwoColumns/DrawerTwoColumns'
import IconUpload from '../../../components/IconUpload/IconUpload'
import * as assetsSlice from '../assetsSlice'
import { Icon } from '../assetsSlice'
import TagsSection from '../icons-panel/icon-item/tags-section/TagsSection'
import styles from './IconDrawer.module.scss'

const IconDrawer: FunctionComponent = () => {
  const drawer = useSelector(assetsSlice.selectDrawer)
  const icon = useSelector(assetsSlice.selectIcon)
  const family = useSelector(assetsSlice.selectFamily)
  const category = useSelector(assetsSlice.selectCategory)
  const subcategory = useSelector(assetsSlice.selectSubcategory)

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [svg, setSVG] = useState(icon?.svg || '')

  const showFields = () => icon?.hash || svg

  const dispatch = useDispatch()

  const openFixRestoreDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: true, type: 'fix-restore', data: icon }))
  }

  const [, editIcon] = useAxios(
    {
      url: `/v4/icons/${icon?.hash}`,
      method: 'PATCH',
    },
    { manual: true }
  )

  const onFinish = async (values: any) => {
    const { name, visible, description } = values
    try {
      if (icon?.hash) {
        setLoading(true)

        const response = await editIcon({
          data: {
            name,
            description,
            visible,
            svg,
            subcategoryHash: subcategory?.hash,
          },
        })
        dispatch(assetsSlice.actions.updateIconOnList(response.data))
        setLoading(false)
        message.success('Icon saved successfully!')
        handleCloseDrawer()
      } else {
        message.error('Icon not found!')
      }
    } catch (error) {
      setLoading(false)
      const responseError = handleError(error)
      message.error(`Something went wrong: ${responseError}`)
    }
  }

  const handleCloseDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: 'icon' }))
  }

  const handleOnUploadComplete = (i: Icon) => {
    setSVG(i.svg)
    form.setFieldsValue({ name: i.name })
    dispatch(assetsSlice.actions.selectedIcon(i))
    message.success('Icon uploaded and saved successfully!')
  }

  const handleUploadFailed = (error: string) => {
    message.error(error)
    setLoading(false)
  }

  useEffect(() => {
    form.setFieldsValue({
      name: icon?.name,
      description: icon?.description,
      visible: icon ? icon?.visible : true,
    })
    setSVG(icon?.svg || '')
  }, [icon, form])

  const leftColumnContent = () => (
    <>
      <DrawerSubtitle text={showFields() ? 'Description' : 'Upload Icon'} />
      {showFields() && (
        <>
          <Form.Item
            label="Asset name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input a name!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Asset description"
            name="description"
            rules={[
              {
                required: false,
                message: 'Please input a description!',
              },
            ]}
          >
            <Input.TextArea autoSize />
          </Form.Item>
        </>
      )}
      <p>SVG source</p>
      <div className={styles.draggerArea}>
        <Form.Item
          name="imageUpload"
          rules={[
            {
              required: !svg,
              message: 'Please upload an icon!',
            },
          ]}
        >
          <IconUpload
            familySlug={family?.slug || ''}
            categorySlug={category?.slug || ''}
            subcategorySlug={subcategory?.slug || ''}
            subcategoryHash={subcategory?.hash || ''}
            iconHash={icon?.hash || ''}
            svg={icon?.svg || ''}
            onUploadComplete={(i) => handleOnUploadComplete(i)}
            onUploadFailed={(error) => handleUploadFailed(error)}
          />
        </Form.Item>
      </div>
    </>
  )

  const rightColumnContent = () => (
    <>
      {showFields() && (
        <>
          <DrawerSubtitle text="Options" />
          <Form.Item label="Show to users" name="visible" valuePropName="checked">
            <Switch defaultChecked />
          </Form.Item>

          {icon && (
            <>
              <DrawerSubtitle text="Information" />
              <small>
                <strong>Created on: </strong>
                {new Date(icon.createdAt).toLocaleString('en-US')}
              </small>
              <br />
              <small>
                <strong>Updated on: </strong>
                {new Date(icon.updatedAt).toLocaleString('en-US')}
              </small>
              <br />
              <small>
                <strong>Icon hash: </strong>
                {icon.hash}
              </small>

              <Divider />
              <DrawerSubtitle text="Actions" />
              <Tooltip title="Fix/Restore this image" placement="bottom">
                <Button
                  type="text"
                  icon={<MedicineBoxOutlined />}
                  onClick={() => openFixRestoreDrawer()}
                >
                  Fix/restore
                </Button>
              </Tooltip>
            </>
          )}
        </>
      )}
    </>
  )

  return (
    <Drawer
      destroyOnClose
      width={640}
      placement="right"
      closable={false}
      maskClosable={false}
      onClose={() => dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: 'icon' }))}
      visible={drawer.type === 'icon' && drawer.visible}
      className={styles.root}
    >
      <Form
        form={form}
        name="icon-form"
        onFinish={onFinish}
        layout="vertical"
        requiredMark="optional"
        initialValues={{ name: icon?.name, visible: icon ? icon.visible : true }}
      >
        <DrawerHeader
          showSaveButton
          isSaving={loading}
          title={icon?.id ? 'Modify asset' : 'New asset'}
          onClickCancel={() => handleCloseDrawer()}
        />

        <DrawerTwoColumns leftCol={leftColumnContent()} rightCol={rightColumnContent()} />
      </Form>
      {icon && showFields() && (
        <>
          <TagsSection icon={icon} tags={icon.tags} />
        </>
      )}
    </Drawer>
  )
}

export default IconDrawer
