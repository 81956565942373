import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import arrowUp from '../../../../assets/arrow-left.svg'
import CursiveText from '../../../../components/CursiveText/CursiveText'
import * as assetsSlice from '../../assetsSlice'
import styles from './SubcategoriesHelp.module.scss'

const SubcategoriesHelp: FunctionComponent = () => {
  const subcategory = useSelector(assetsSlice.selectSubcategory)

  if (subcategory) return null

  return (
    <div className={styles.root}>
      <img src={arrowUp} alt="Arrow up" />
      <CursiveText text="And now select the subcategory" />
    </div>
  )
}

export default SubcategoriesHelp
