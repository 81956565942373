import React, { FunctionComponent } from 'react'
import styles from './DrawerSubtitle.module.scss'

interface DrawerSubtitleProps {
  text: string
}

const DrawerSubtitle: FunctionComponent<DrawerSubtitleProps> = ({ text }) => (
  <div className={styles.root}>
    <h3>{text} </h3>
  </div>
)

export default DrawerSubtitle
