import { Form, FormInstance, Input, Select, Tooltip } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useSelector } from 'react-redux'
import React, { FunctionComponent, useState } from 'react'
import DrawerSubtitle from '../../../../../components/DrawerSubtitle/DrawerSubtitle'
import FamilyFormUploadCover from '../family-form-upload-cover/FamilyFormUploadCover'
import { Family, FamilyGroup, ProductTypeEnum, StyleFilterEnum } from '../../../assetsSlice'
import AntFormItemExplain from '../../../../../components/AntFormItemExplain/AntFormItemExplain'
import useAxios from '../../../../../app/api/apiHook'
import * as assetsSlice from '../../../assetsSlice'

interface FamilyFormLeftColumnProps {
  imagePublicId: string
  form: FormInstance<Family>
}

const FamilyFormLeftColumn: FunctionComponent<FamilyFormLeftColumnProps> = ({
  imagePublicId,
  form,
}) => {
  const [coverUrl, setCoverUrl] = useState(imagePublicId)
  const [isStyleInputVisible, setIsStyleInputVisible] = useState(
    form.getFieldValue('productType') === ProductTypeEnum.ICONS
  )
  const families = useSelector(assetsSlice.selectFamiliesByOrder)

  const [{ data: familyGroups, loading: isLoadingList }] = useAxios<FamilyGroup[]>({
    url: `/v4/family-groups`,
    method: 'GET',
  })

  return (
    <>
      <DrawerSubtitle text="Description" />
      <Form.Item
        label="Family Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input a name!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Family Description" name="description">
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item
        label="Product type"
        name="productType"
        rules={[
          {
            required: true,
            message: 'Please input a product type!',
          },
        ]}
      >
        <Select
          placeholder="Select product type"
          onChange={(productType) => {
            setIsStyleInputVisible(productType === ProductTypeEnum.ICONS)
          }}
        >
          {Object.values(ProductTypeEnum).map((productType) => (
            <Select.Option key={productType} value={productType}>
              {productType}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Family Group" name="familyGroupHash">
        <Select placeholder="Select a family group">
          {isLoadingList && <Select.Option value="loading">Loading...</Select.Option>}
          {familyGroups?.map((familyGroup) => (
            <Select.Option key={familyGroup.hash} value={familyGroup.hash}>
              {familyGroup.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Related Family" name="relatedFamilyHash">
        <Select<string, { value: string; children: string }>
          placeholder="Select a related family"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            if (option?.children) {
              return option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            return false
          }}
        >
          {isLoadingList && <Select.Option value="loading">Loading...</Select.Option>}
          {families?.map((family) => (
            <Select.Option key={family.hash} value={family.hash}>
              {family.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {isStyleInputVisible ? (
        <Form.Item
          label="Family style"
          name="style"
          rules={[
            {
              required: true,
              message: 'Please select style!',
            },
          ]}
        >
          <Select placeholder="Select family style">
            {Object.values(StyleFilterEnum).map((style) => (
              <Select.Option key={style} value={style}>
                {style}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : null}

      <p>Cover</p>
      <Tooltip
        placement="bottom"
        title={coverUrl ? 'Click or drag file to this area to upload a new family' : ''}
      >
        <FamilyFormUploadCover
          onUploadComplete={(url) => setCoverUrl(url)}
          defaultImage={imagePublicId}
        />
      </Tooltip>

      <Form.Item
        label={
          <div>
            Slug
            <br />
            <AntFormItemExplain
              type="text"
              message="The slug will appears in the URL - Make sure that there isn't two families with the same slug."
            />
          </div>
        }
        name="slug"
        rules={[
          {
            required: true,
            message: 'Please input a name!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Author name" name="authorName">
        <Input />
      </Form.Item>

      <Form.Item label="Author link" name="authorLink">
        <Input />
      </Form.Item>

      <Form.Item label="License name" name="licenseName">
        <Input />
      </Form.Item>

      <Form.Item label="License link" name="licenseLink">
        <Input />
      </Form.Item>

      <Form.Item
        label={
          <div>
            Payment link
            <br />
            <AntFormItemExplain
              type="text"
              message="Paste the Stripe payment link related to this family to sell them individually. The link to buy the family separately will appear on the family page. The payment link must be obtained from Stripe, if the product for this family has not been created, check the notion doc using the link bellow or ask support to do so."
            />
            <a href="https://www.notion.so/How-to-create-a-Stripe-product-to-sell-a-family-separately-d00b327d3a66466c861356f910610027">
              How to create a Stripe product to sell a family separately
            </a>
          </div>
        }
        name="paymentLink"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={
          <div>
            SendOwl File ID
            <br />
            <AntFormItemExplain
              type="text"
              message="Paste the SendOwl file id related to this family. This is needed to update the family assets on SendOwl. The SendOwl file id must be obtained from Stripe, if the product for this family has not been created, check the notion doc using the link bellow or ask support to do so."
            />
            <a href="https://www.notion.so/How-to-create-a-Stripe-product-to-sell-a-family-separately-d00b327d3a66466c861356f910610027">
              How to create a Stripe product to sell a family separately
            </a>
          </div>
        }
        name="sendOwlFileId"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Meta Title"
        name="metaTitle"
        rules={[
          {
            message: 'Please input a name!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Family Meta Description" name="metaDescription">
        <TextArea rows={4} />
      </Form.Item>
    </>
  )
}

export default FamilyFormLeftColumn
