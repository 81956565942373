import React, { FunctionComponent } from 'react'
import arrowDown from '../../../../assets/arrow-up.svg'
import emptyImage from '../../../../assets/empty.svg'
import CursiveText from '../../../../components/CursiveText/CursiveText'
import styles from './IconEmptyState.module.scss'

const IconEmptyState: FunctionComponent = () => (
  <div className={styles.root}>
    <img className={styles.emptyImage} src={emptyImage} alt="Empty" />
    <CursiveText fontSize="16px" text="Theres no icons for this subcategory" />
    <div className={styles.down}>
      <CursiveText
        fontSize="16px"
        text={'Create one clicking on the "+ New Asset" button below'}
        className={styles.displayInline}
      />
      <img className={styles.arrowDown} src={arrowDown} alt="Arrow down" />
    </div>
  </div>
)

export default IconEmptyState
