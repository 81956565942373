import React, { FunctionComponent } from 'react'
import useAxios from '../../../app/api/apiHook'
import styles from './UsersStats.module.scss'

const UsersStats: FunctionComponent = () => {
  const [{ data }] = useAxios<{ totalUsers: number }>('/v4/users/counters', {})
  return (
    <div className={styles.root}>
      <p>
        <b>{data?.totalUsers}</b> users subscribed
      </p>
    </div>
  )
}

export default UsersStats
