import React, { FunctionComponent } from 'react'
import styles from './HeaderBig.module.scss'

interface HeaderBigProps {
  leftCol: React.ReactNode
  rightCol: React.ReactNode
}

const HeaderBig: FunctionComponent<HeaderBigProps> = ({ leftCol, rightCol }) => (
  <div className={styles.root}>
    <div className={styles.inner}>
      <div className={styles.left}>{leftCol}</div>

      <div className={styles.right}>{rightCol}</div>
    </div>
  </div>
)

export default HeaderBig
