import React, { FunctionComponent } from 'react'
import Tags from '../../features/tags/Tags'
import styles from './TagsPagesPage.module.scss'

const TagsPagesPage: FunctionComponent = () => (
  <div className={styles.root}>
    <Tags />
  </div>
)

export default TagsPagesPage
