export const moveItemRight = <T>(array: T[], item: T): T[] => {
  const index = array.indexOf(item)
  if (index === -1 || index === array.length - 1) {
    return array
  }

  const newArray = [...array]
  const temp = newArray[index]
  newArray[index] = newArray[index + 1]
  newArray[index + 1] = temp

  return newArray
}

export const moveItemLeft = <T>(array: T[], item: T): T[] => {
  const index = array.indexOf(item)
  if (index === -1 || index === 0) {
    return array
  }

  const newArray = [...array]
  const temp = newArray[index]
  newArray[index] = newArray[index - 1]
  newArray[index - 1] = temp

  return newArray
}
