import { UploadOutlined } from '@ant-design/icons'
import { Button, Drawer, message, Spin } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import React, { FunctionComponent, useState } from 'react'

import { getAuthenticationToken } from '../../app/auth/authentication'
import envConfig from '../../app/config'
import styles from './TagsUploadSpreadsheet.module.scss'
import { TagsPagesStatus } from './types'

interface TagsUploadSpreadsheetProps {
  visible: boolean
  onClose(): void
  onError?(error: Error): void
  onSuccess(tags: TagsPagesStatus): void
}

const TagsUploadSpreadsheet: FunctionComponent<TagsUploadSpreadsheetProps> = ({
  visible,
  onClose,
  onSuccess,
  onError,
}) => {
  // const [isUploadDrawerVisible, setIsUploadDrawerVisible] = useState(false)

  const [isUploading, setIsUploading] = useState(false)
  const [userAuthorizationToken, setUserAuthorizationToken] = useState<string>('')

  const getUploadUrl = async () => {
    const token = await getAuthenticationToken()

    if (token) {
      setUserAuthorizationToken(token)
    }

    return `${envConfig.API_URL}/v4/preferences/tags-pages/upload`
  }

  return (
    <Drawer
      className={styles.root}
      destroyOnClose
      width={720}
      placement="right"
      closable={false}
      maskClosable={false}
      onClose={() => onClose()}
      visible={visible}
    >
      <div className={styles.header}>
        <h2>Upload family</h2>

        <div className={styles.right}>
          <Button onClick={() => onClose()} className={styles.button} disabled={isUploading}>
            Close
          </Button>
        </div>
      </div>

      {isUploading ? (
        <div className={styles.loadingArea}>
          Uploading spreadsheet...
          <br />
          <Spin /> <br />
          Please wait, this can take some minutes to complete.
        </div>
      ) : (
        <p>
          Here you can upload a excel spreadsheet(.xlsx) and it'll <b>OVERRIDE</b> all the values we
          have for the tags pages. So make sure the spreadsheet has the correct values. <br />
          The spreadsheet should contain the exact same format as the one you can download on
          "Export Spreadsheet" button. It consists of one file cantaining four different sheets, one
          for each product(icons/illustrations/emojis/elements), and inside each sheet it should
          contain two columns: <b>Tag</b> - tag will contain the tag name, <b>Indexed</b> - that
          should contain yes/no values. Empty values in the Indexed column will be considered as
          'no'.
        </p>
      )}

      <div
        className={styles.draggerArea}
        style={isUploading ? { display: 'none' } : { display: 'flex' }} // We just hide, because we need to keep the component rendered, otherwise the upload will not work - as we need to keep hearing the onChange event
      >
        <Dragger
          name="file"
          multiple={false}
          action={getUploadUrl}
          headers={{ Authorization: userAuthorizationToken }}
          onChange={(info) => {
            if (info.file.status === 'error') {
              setIsUploading(false)
              message.error(
                `${info?.file?.response?.error} - Please ensure that the spreadsheet is properly formatted.` ||
                  'Something went wrong, please try again'
              )
              if (onError) {
                onError(new Error(info?.file?.response?.error))
              }
            }
            if (info.file.status === 'uploading') {
              setIsUploading(true)
            }
            if (info.file.status === 'done') {
              message.success('Tags pages imported succesfully!', 5)
              setIsUploading(false)
              onSuccess(info.file.response.importedTags)
            }
          }}
          showUploadList={false}
          accept=".xlsx"
        >
          <p className="ant-upload-drag-family">
            <UploadOutlined width={18} />
          </p>
          <p className={styles.draggerText}>Click or drag spreadsheet to this area to upload</p>
          <p className={styles.draggerTextSmall}>File type accepted : .xlsx</p>
        </Dragger>
      </div>
    </Drawer>
  )
}

export default TagsUploadSpreadsheet
