import { Button, Collapse, Drawer, Form, message, Spin, Tooltip, Typography } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../app/api/apiHook'
import handleError from '../../../app/api/handleError'
import DrawerHeader from '../../../components/DrawerHeader/DrawerHeader'
import type { Icon } from '../../assets/assetsSlice'
import * as assetsSlice from '../../assets/assetsSlice'
import ChangesListFix from '../changes-list-fix/ChangesListFix'
import Restore from './Restore'
import SVGComparison from './SVGComparison'

const { Text } = Typography

const FixRestoreDrawer: FunctionComponent = () => {
  const drawerType = 'fix-restore'
  const [form] = Form.useForm()
  const drawer = useSelector(assetsSlice.selectDrawer)
  // At first the fix is in simulation mode, then the admin confirms the change
  const [isSimulation, setIsSimulation] = useState(true)
  const [errorText, setErrorText] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [fixedSVGPreview, setFixedSVGPreview] = useState<string | null>(null)
  const family = useSelector(assetsSlice.selectFamily)

  const icon = drawer.data as Icon

  const dispatch = useDispatch()

  const handleCloseDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: drawerType, data: null }))
  }

  const handleSubmit = async () => {
    await form.submit()
  }

  const [, fixIcon] = useAxios(
    {
      url: `/v4/image-engine/fix-icon/${icon.hash}?simulation=${isSimulation}`,
      method: 'PATCH',
    },
    { manual: true, autoCancel: false }
  )

  const handleFix = async (shouldSimulate: boolean) => {
    try {
      setLoading(true)
      setIsSimulation(shouldSimulate)
      setErrorText(null)

      const {
        data: { fixedSVG },
      } = await fixIcon({ data: { isSimulation } })

      if (isSimulation) {
        setFixedSVGPreview(fixedSVG)
      } else {
        setFixedSVGPreview(null)
        dispatch(assetsSlice.actions.updateIconOnList({ ...icon, svg: fixedSVG }))
        dispatch(
          assetsSlice.actions.toggleDrawer({
            visible: true,
            type: 'fix-restore',
            data: { ...icon, svg: fixedSVG },
          })
        )
        message.success(`Image's SVG has been fixed`)
      }
    } catch (error) {
      // @TODO For some reason this functionality throws a cancel error when clicked
      // the second time. Cause is unknown, it should be fixed.
      if (axios.isCancel(error)) {
        message.warning('Please click again')
      } else {
        const responseError = handleError(error)
        message.error(`Could not fix an image`)
        setErrorText(responseError)
      }
    } finally {
      setLoading(false)
    }
  }

  if (!icon || !family) {
    return null
  }

  return (
    <>
      <Drawer
        destroyOnClose
        width={720}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() => handleCloseDrawer()}
        visible={drawer.type === drawerType && drawer.visible}
      >
        <DrawerHeader
          showSaveButton={false}
          isSaving={loading}
          title="Fix or restore an image"
          onClickCancel={() => handleCloseDrawer()}
          onClickSave={handleSubmit}
          showDeleteButton={false}
        />
        <h3>Here you can automatically fix an image SVG or restore it to its original state</h3>
        <Collapse defaultActiveKey={['restore']} accordion ghost>
          <Collapse.Panel header="Restore SVG" key="restore">
            <SVGComparison
              firstSVG={icon.svg}
              firstTitle="Image's current SVG"
              secondSVG={icon.originalSvg}
              secondTitle="Image's original SVG"
            />
            <Restore icon={icon} setLoading={setLoading} loading={loading} />
          </Collapse.Panel>
          <Collapse.Panel header="Fix SVG" key="fix">
            <Spin spinning={loading}>
              <h4>Fix SVG automatically</h4>
              <ChangesListFix strokeAllowed={family.strokeAllowed} />
              <Tooltip title="Preview how the SVG will look like after the fix. No changes will be saved in the database when you click this.">
                <Button htmlType="button" size="large" onClick={() => handleFix(true)}>
                  Preview SVG fix
                </Button>
              </Tooltip>
              {fixedSVGPreview && (
                <>
                  <SVGComparison
                    firstSVG={icon.svg}
                    firstTitle="Image's current SVG"
                    secondSVG={fixedSVGPreview}
                    secondTitle="Image to save after fixing"
                  />
                  {icon.svg === fixedSVGPreview ? (
                    <Text type="secondary">
                      Image's SVG after fixing will stay the same, so it is already fixed.
                    </Text>
                  ) : (
                    <Tooltip title="Save these changes in the database. If anything breaks you can restore this image's SVG to its original state above.">
                      <Button htmlType="button" size="large" onClick={() => handleFix(false)}>
                        Save fixed SVG
                      </Button>
                    </Tooltip>
                  )}
                </>
              )}
            </Spin>
          </Collapse.Panel>
        </Collapse>
        {errorText && <Text type="danger">{errorText}</Text>}
      </Drawer>
    </>
  )
}

export default FixRestoreDrawer
