import { UnlockOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import copy from 'copy-to-clipboard'
import React, { FunctionComponent, useState } from 'react'
import useAxios from '../../../../../app/api/apiHook'
import handleError from '../../../../../app/api/handleError'

interface CopyPasswordRecoverUrlButtonProps {
  userHash: string
}
const CopyPasswordRecoverUrlButton: FunctionComponent<CopyPasswordRecoverUrlButtonProps> = ({
  userHash,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const [, getRecoverLink] = useAxios(
    {
      url: `/v4/users/${userHash}/password-url`,
      method: 'GET',
    },
    { manual: true }
  )

  const handleGetPasswordRecover = async () => {
    try {
      setIsLoading(true)
      const response = await getRecoverLink()
      setIsLoading(false)
      copy(response.data.url)
      message.success('Password reset link copied to your clipboard successfully!')
    } catch (error) {
      setIsLoading(false)
      const responseError = handleError(error)
      message.error(`Something went wrong: ${responseError}`)
    }
  }

  return (
    <Button
      type="link"
      icon={<UnlockOutlined />}
      loading={isLoading}
      onClick={handleGetPasswordRecover}
    >
      Copy Password Reset Link
    </Button>
  )
}

export default CopyPasswordRecoverUrlButton
