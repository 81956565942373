import { LoadingOutlined } from '@ant-design/icons'
import { Progress } from 'antd'
import React, { FunctionComponent, useEffect, useState } from 'react'

const ColorRepaintResultsProgress: FunctionComponent<{ total: number }> = ({ total }) => {
  const [estimatedTime, setEstimatedTime] = useState(0)
  const [percentageEstimateCompletion, setPercentageEstimateCompletion] = useState(0)

  /* This is just a raw calculation based on the amount of assets in order to give some feedback to the user 
  - This is not an accurate representation of the proggress but it's a progress based on the estimated time to complete
  - It's using a calculation considering 550 assets per second - this value was set based on experimentation from the time that it took to complete previous importations */
  useEffect(() => {
    setEstimatedTime(total / 550)
    setPercentageEstimateCompletion(0)
  }, [total])

  useEffect(() => {
    const timeoutID = setInterval(() => {
      let percentage = +(100 / estimatedTime + percentageEstimateCompletion).toFixed(2)
      /* If estimated percentage is greater than 99 - but we didn't receive success from api then keep it at 99  */
      if (percentage >= 99) {
        percentage = 99
      }
      setPercentageEstimateCompletion(percentage)
    }, 1000)

    return () => window.clearTimeout(timeoutID)
  }, [percentageEstimateCompletion, estimatedTime])

  return (
    <div>
      <LoadingOutlined />
      <p>
        {`Re-painting assets - The estimated time for completion is around ${
          Math.round(estimatedTime / 60) <= 0
            ? `${Math.round(estimatedTime)} seconds`
            : `${Math.round(estimatedTime / 60)} minutes.`
        }`}
      </p>
      <Progress percent={percentageEstimateCompletion} />
    </div>
  )
}

export default ColorRepaintResultsProgress
