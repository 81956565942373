import React, { FunctionComponent } from 'react'
import styles from './CursiveText.module.scss'

interface DrawerHeaderProps {
  fontSize?: string
  text: string
  className?: string
}

const CursiveText: FunctionComponent<DrawerHeaderProps> = ({ fontSize, text, className }) => (
  <p className={`${styles.root} ${className || ''}`} style={{ fontSize: fontSize || '24px' }}>
    {text}
  </p>
)

export default CursiveText
