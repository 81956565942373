import { UploadOutlined } from '@ant-design/icons'
import { Button, Checkbox, Drawer, Form } from 'antd'
import Text from 'antd/lib/typography/Text'
import { UploadChangeParam } from 'antd/lib/upload'
import Dragger from 'antd/lib/upload/Dragger'
import { UploadFile } from 'antd/lib/upload/interface'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthenticationToken } from '../../../../app/auth/authentication'
import * as authSlice from '../../../../app/auth/authSlice'
import envConfig from '../../../../app/config'
import AntFormItemExplain from '../../../../components/AntFormItemExplain/AntFormItemExplain'
import * as assetsSlice from '../../assetsSlice'
import { Family } from '../../assetsSlice'
import UploadProgress from './family-pack-upload-progress/FamilyPackUploadProgress'
import FamilyPackUploadResults from './family-pack-upload-results/FamilyPackUploadResults'
import styles from './FamilyPackUploadDrawer.module.scss'

export interface IconImportFailed {
  path: string
  error: string
}

export interface ImportIconPackResults {
  iconsImported: string[]
  iconsFailed: IconImportFailed[]
}

const FamilyPackUploadDrawer: FunctionComponent = () => {
  const drawer = useSelector(assetsSlice.selectDrawer)
  const user = useSelector(authSlice.selectLoggedInUser)
  const family = drawer.data as Family
  const [fileSize, setFileSize] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')

  const [skipCloudinaryUploads, setSkipCloudinaryUploads] = useState(false)

  const [results, setResults] = useState<ImportIconPackResults | null>(null)

  const [uploading, setUploading] = useState(false)
  const [uploadToken, setUploadToken] = useState<string>('')

  const dispatch = useDispatch()

  const handleCloseDrawer = () => {
    dispatch(
      assetsSlice.actions.toggleDrawer({ visible: false, type: 'family-upload', data: null })
    )
  }

  const handleUploadComplete = (data: ImportIconPackResults) => {
    setUploading(false)
    setResults(data)
    dispatch(assetsSlice.getCategories(family.hash))
  }

  const handleUploading = (size: number) => {
    setFileSize(size)
    setUploading(true)
  }

  const onCheckboxChange = (e: { target: { checked: boolean } }) => {
    setSkipCloudinaryUploads(e.target.checked)
  }

  const normalizeFileListFormat = (fileUploadEvent: UploadChangeParam): UploadFile[] =>
    fileUploadEvent.fileList

  const getUploadUrl = async () => {
    const token = await getAuthenticationToken()

    if (token) {
      setUploadToken(token)
    }

    return `${envConfig.API_URL}/v4/families/${family.hash}/upload-pack?email=${user?.email}&skipCloudinary=${skipCloudinaryUploads}`
  }

  return (
    <Drawer
      destroyOnClose
      width={720}
      placement="right"
      closable={false}
      maskClosable={false}
      onClose={() =>
        dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: 'family-upload' }))
      }
      visible={drawer.type === 'family-upload' && drawer.visible}
    >
      <div className={styles.header}>
        <h2>Upload family</h2>

        <div className={styles.right}>
          <Button onClick={handleCloseDrawer} className={styles.button}>
            Close
          </Button>
        </div>
      </div>
      <div>
        {results ? (
          <FamilyPackUploadResults results={results} />
        ) : (
          <Form>
            <Form.Item
              valuePropName="fileList"
              getValueFromEvent={normalizeFileListFormat}
              name="fileList"
            >
              {uploading && (
                <UploadProgress resultsReceived={results !== null} fileSize={fileSize} />
              )}
              <>
                <div className={styles.checkbox}>
                  <Checkbox onChange={onCheckboxChange}>
                    Don&apos;t upload images to Cloudinary
                  </Checkbox>
                  <br />
                  <Text type="warning">
                    If you select this option we&apos;ll not upload the images to Cloudinary. This
                    is usefull when the family will be always free or when we intend to re-paint the
                    family right after.
                  </Text>
                </div>
                <div className={styles.draggerArea}>
                  {errorMessage && <AntFormItemExplain message={errorMessage} type="error" />}

                  <Dragger
                    name="pack"
                    multiple={false}
                    action={getUploadUrl}
                    headers={{ Authorization: uploadToken }}
                    onChange={(info) => {
                      if (info.file.status === 'error') {
                        setFileSize(0)
                        setUploading(false)
                        console.error(info)
                        setErrorMessage(
                          info?.file?.response?.error || 'Something went wrong, please try again'
                        )
                      }
                      if (info.file.status === 'uploading') {
                        handleUploading(info.file.size || 0)
                      }
                      if (info.file.status === 'done') {
                        handleUploadComplete(info.file.response.data)
                      }
                    }}
                    showUploadList={false}
                    accept=".zip"
                  >
                    <p className="ant-upload-drag-family">
                      <UploadOutlined width={18} />
                    </p>
                    <p className={styles.draggerText}>Click or drag file to this area to upload</p>
                    <p className={styles.draggerTextSmall}>File type accepted : .zip</p>
                  </Dragger>
                </div>
              </>
            </Form.Item>
          </Form>
        )}
      </div>
    </Drawer>
  )
}

export default FamilyPackUploadDrawer
