import auth from './firebaseAuthentication'

const TOKEN_KEY = '@Streamline::ApiToken'

export enum AuthenticationProvider {
  STREAMLINE = 'streamline',
  FIREBASE = 'firebase',
}

export const getAuthenticationTokenFromLocalStorage = () => window.localStorage.getItem(TOKEN_KEY)
export const getAuthenticationToken = async (): Promise<string | null> => {
  if (auth.currentUser) {
    return auth.currentUser.getIdToken()
  }

  return null
}
export const setAuthenticationTokenLocalStorage = (token: string) =>
  window.localStorage.setItem(TOKEN_KEY, token)
export const clearAuthenticationTokenFromLocalStorage = () =>
  window.localStorage.removeItem(TOKEN_KEY)
