import { FileSearchOutlined } from '@ant-design/icons'
import { Button, message, Tooltip } from 'antd'
import React, { FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useAxios from '../../../../app/api/apiHook'
import * as assetsSlice from '../../assetsSlice'

const UpdateElasticButton: FunctionComponent = () => {
  const family = useSelector(assetsSlice.selectFamily)

  const [{ data, loading, error }, updateElasticIndex] = useAxios(
    {
      url: `/v4/open-search/index/${family?.slug}`,
      method: 'POST',
    },
    { manual: true }
  )

  useEffect(() => {
    if (data) message.success('Family re-indexed on OpenSearch successfully')
  }, [data])

  useEffect(() => {
    if (error) message.error(`Family re-indexing failed: ${data.message ? data.message : error}`)
  }, [error, data])

  return (
    <Tooltip
      title="Click here to update the elastic index for this family, this can take several minutes depending on the family size. Feel free to close the window and keep using the admin."
      placement="bottom"
    >
      <Button
        loading={loading}
        type="text"
        icon={<FileSearchOutlined />}
        onClick={() => updateElasticIndex()}
      >
        Re-index elastic
      </Button>
    </Tooltip>
  )
}

export default UpdateElasticButton
