import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router'
import { Routes } from '../../../app/routes/app-router'
import HeaderBig from '../../../components/HeaderBig/HeaderBig'
import UsersSearch from './users-search/UsersSearch'

const UsersHeader: FunctionComponent = () => {
  const history = useHistory()

  return (
    <HeaderBig
      leftCol={<UsersSearch />}
      rightCol={
        <Button
          icon={<PlusOutlined />}
          onClick={() => history.push(Routes.USERS_DETAILS.replace(':userHash', 'new'))}
        >
          New User
        </Button>
      }
    />
  )
}

export default UsersHeader
