import axios from 'axios'
import { makeUseAxios } from 'axios-hooks'
import { getAuthenticationToken } from '../auth/authentication'
import configEnv from '../config'

const axiosInstance = axios.create({ baseURL: configEnv.API_URL || '' })
// request interceptor to add token to request headers
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getAuthenticationToken()

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        authorization: token,
        tool: 'admin',
      }
    }

    return config
  },
  (error) => Promise.reject(error)
)

const useAxios = makeUseAxios({
  axios: axiosInstance,
})

export default useAxios
