import { Card } from 'antd'
import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import * as usersSlice from '../../usersSlice'
import styles from './UserDetailsAccountMembersCard.module.scss'

const UserDetailsAccountMembersCard: FunctionComponent = () => {
  const user = useSelector(usersSlice.selectUser)

  if (!user) return null

  return (
    <Card title="Multi-account" type="inner" className={styles.AccountMembersCard}>
      {user.availableSeats.total > 0 && (
        <div className={styles.AvailableSeats}>
          <b>
            {user.availableSeats.available}/{user.availableSeats.total} memberships available
          </b>
        </div>
      )}

      {user.memberFrom && (
        <div className={styles.AccountActivatedBy}>
          <b>Account activated by:</b>

          <span>{user.memberFrom.email}</span>
        </div>
      )}

      {user.members.length > 0 && (
        <>
          <b>Active members:</b>

          <ul>
            {user.members.map((member) => (
              <li>{member.email}</li>
            ))}
          </ul>
        </>
      )}

      {user.invitationsSent.length > 0 && (
        <>
          <b>Invited members:</b>

          <ul>
            {user.invitationsSent.map((invitation) => (
              <li>{invitation.email}</li>
            ))}
          </ul>
        </>
      )}

      {user.invitationsReceived.length > 0 && (
        <>
          <b>Invited by:</b>

          <ul>
            {user.invitationsReceived.map((invitation) => (
              <li>{invitation.email}</li>
            ))}
          </ul>
        </>
      )}
    </Card>
  )
}

export default UserDetailsAccountMembersCard
