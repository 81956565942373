export type UnindexedFamily = {
  name: string
  slug: string
}

export enum IndexingStatusEnum {
  NONE = 'none',
  IN_PROGRESS = 'in progress',
  FINISHED = 'finished',
}

export type IndexingResult = {
  indexName: string
  status: IndexingStatusEnum
  startedAt?: Date
  finishedAt?: Date
  unindexedFamilies: UnindexedFamily[]
  totalIcons?: number
  totalIconsIndexed?: number
}
