import { Col, Row } from 'antd'
import React, { FunctionComponent } from 'react'
import UsersHeader from './users-header/UsersHeader'
import UsersStats from './users-stats/UsersStats'
import UsersTable from './users-table/UsersTable'
import styles from './Users.module.scss'

const Users: FunctionComponent = () => (
  <>
    <UsersHeader />
    <div className={styles.root}>
      <Row justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} lg={20}>
          <UsersStats />
          <UsersTable />
        </Col>
      </Row>
    </div>
  </>
)

export default Users
