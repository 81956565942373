import { Typography, Collapse } from 'antd'
import React, { FunctionComponent } from 'react'
import styles from './FixRestore.module.scss'

const { Text } = Typography

const SVGComparison: FunctionComponent<{
  firstSVG: string
  secondSVG: string
  firstTitle: string
  secondTitle: string
}> = ({ firstSVG, secondSVG, firstTitle, secondTitle }) => (
  <>
    <h3>Compare SVGs</h3>
    <div className={styles.comparison}>
      <div>
        <h4>{firstTitle}</h4>
        <figure className={styles.figure}>
          <img
            alt={firstTitle}
            src={`data:image/svg+xml;utf8,${encodeURIComponent(firstSVG)}`}
            className={styles.image}
          />
        </figure>
        <Collapse ghost>
          <Collapse.Panel header={`${firstTitle} source code`} key="current">
            <Text code>{firstSVG}</Text>
          </Collapse.Panel>
        </Collapse>
      </div>
      <div>
        {secondSVG ? (
          <>
            <h4>{secondTitle}</h4>
            <figure className={styles.figure}>
              <img
                alt={secondTitle}
                src={`data:image/svg+xml;utf8,${encodeURIComponent(secondSVG)}`}
                className={styles.image}
              />
            </figure>
            <Collapse ghost>
              <Collapse.Panel header={`${secondTitle} source code`} key="original">
                <Text code>{secondSVG}</Text>
              </Collapse.Panel>
            </Collapse>
          </>
        ) : (
          <p>There is nothing to compare with yet</p>
        )}
      </div>
    </div>
  </>
)

export default SVGComparison
