import { Drawer, Form, Input, InputNumber, message, Switch } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../app/api/apiHook'
import handleError from '../../../app/api/handleError'
import AntFormItemExplain from '../../../components/AntFormItemExplain/AntFormItemExplain'
import DrawerHeader from '../../../components/DrawerHeader/DrawerHeader'
import DrawerSubtitle from '../../../components/DrawerSubtitle/DrawerSubtitle'
import DrawerTwoColumns from '../../../components/DrawerTwoColumns/DrawerTwoColumns'
import * as assetsSlice from '../assetsSlice'
import { Category } from '../assetsSlice'
import CategoryRemoveModal from './category-remove-modal/CategoryRemoveModal'

const CategoryDrawer: FunctionComponent = () => {
  const [form] = Form.useForm()
  const drawer = useSelector(assetsSlice.selectDrawer)
  const family = useSelector(assetsSlice.selectFamily)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const category = drawer.data as Category

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const [, editCategory] = useAxios(
    {
      url: `/v4/categories/${category?.hash}`,
      method: 'PATCH',
    },
    { manual: true }
  )

  const [, createCategory] = useAxios(
    {
      url: '/v4/categories',
      method: 'POST',
    },
    { manual: true }
  )

  const handleCloseDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: 'category', data: null }))
  }

  const onFinish = async (values: any) => {
    const { name, visible, isFree, isNew, order } = values
    try {
      setLoading(true)
      let response
      if (category?.hash) {
        response = await editCategory({
          data: {
            name,
            visible,
            isFree,
            isNew,
            familyHash: family?.hash,
            order,
          },
        })
        dispatch(assetsSlice.actions.updateCategoryOnList(response.data))
      } else {
        response = await createCategory({
          data: {
            name,
            visible,
            isFree,
            isNew,
            familyHash: family?.hash,
            order,
          },
        })
        dispatch(assetsSlice.actions.addCategoryOnList(response.data))
      }
      setLoading(false)
      message.success('Category saved with success!')
      handleCloseDrawer()
    } catch (error) {
      console.error(error)
      setLoading(false)
      const responseError = handleError(error)
      message.error(`Something went wrong: ${responseError}`)
    }
  }

  const leftColumnContent = () => (
    <>
      <DrawerSubtitle text="Description" />
      <Form.Item
        label="Category Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input a name!',
          },
        ]}
      >
        <Input />
      </Form.Item>
    </>
  )

  const rightColumnContent = () => (
    <>
      <DrawerSubtitle text="Options" />
      <Form.Item label="Show to users" name="visible" valuePropName="checked">
        <Switch defaultChecked />
      </Form.Item>

      <Form.Item label="Is category 'Free'?" name="isFree" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item label="Is category 'New'?" name="isNew" valuePropName="checked">
        <Switch />
      </Form.Item>

      <DrawerSubtitle text="Preferences" />
      <Form.Item
        label="Sorting number"
        name="order"
        rules={[
          {
            required: true,
            message: 'Please input a category order!',
          },
        ]}
      >
        <InputNumber min={1} max={999} />
      </Form.Item>
      <AntFormItemExplain type="text" message="Help to sort categories. 01 = first in the list" />
    </>
  )

  return (
    <>
      <Drawer
        destroyOnClose
        width={640}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() =>
          dispatch(
            assetsSlice.actions.toggleDrawer({ visible: false, type: 'category', data: null })
          )
        }
        visible={drawer.type === 'category' && drawer.visible}
      >
        <Form
          form={form}
          name="category-form"
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
          initialValues={{
            name: category?.name,
            order: category?.order || 1,
            visible: category ? category.visible : true,
            isFree: category ? category.isFree : false,
            isNew: category ? category.isNew : false,
          }}
        >
          <DrawerHeader
            showSaveButton
            isSaving={loading}
            title={drawer.data?.hash ? 'Modify Category' : 'New Category'}
            onClickCancel={() => handleCloseDrawer()}
            showDeleteButton={!!category?.hash}
            onClickDelete={() => setIsDeleteModalVisible(true)}
          />

          <DrawerTwoColumns leftCol={leftColumnContent()} rightCol={rightColumnContent()} />
        </Form>
      </Drawer>
      {isDeleteModalVisible && (
        <CategoryRemoveModal onCancel={() => setIsDeleteModalVisible(false)} category={category} />
      )}
    </>
  )
}

export default CategoryDrawer
