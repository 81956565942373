import { Button, Modal, Checkbox, message } from 'antd'
import React, { useState } from 'react'
import useAxios from '../../../app/api/apiHook'
import styles from './PublishChangesButton.module.scss'

/**
 * This is instead of a feature flag.
 * Will be updated with https://height.app/hD7L-qOva/T-6277
 */
const shouldDisplayWarning = false

/**
 * @deprecated, remove on 1st Jan 2025
 * @see https://height.app/hD7L-qOva/T-6263
 */
function PublishChangesButton() {
  const [loading, setIsLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  })

  const [, clearCacheRequest] = useAxios(
    {
      url: '/v4/families/assets-cache',
      method: 'DELETE',
    },
    { manual: true }
  )

  const handlePublishChangesRequest = async () => {
    try {
      setIsLoading(true)
      await clearCacheRequest()
      setIsLoading(false)
      message.success('Changes published successfully!')
      setIsModalVisible(false)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
      message.error('Changes were not published, please try again')
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleCheckboxChange = (checkboxName: keyof typeof checkboxes) => {
    setCheckboxes((prev) => ({
      ...prev,
      [checkboxName]: !prev[checkboxName],
    }))
  }

  const isSubmitEnabled = Object.values(checkboxes).every(Boolean)

  return (
    <>
      <Button
        className={styles.publishChangesButton}
        onClick={() => (shouldDisplayWarning ? showModal() : handlePublishChangesRequest())}
      >
        Publish Changes
      </Button>

      <Modal
        title="Are you sure the automatic update didn't work and you want to spend money to manually publish all changes?"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handlePublishChangesRequest}
            disabled={!isSubmitEnabled}
          >
            Spend $10+ and publish changes
          </Button>,
        ]}
      >
        <p>
          Warning: This is a deprecated feature. Now admin changes will be reflected in Streamline
          automatically after 5-30 minutes, without pressing this button. Please proceed only if
          changes aren't reflected.
        </p>
        <Checkbox checked={checkboxes.checkbox1} onChange={() => handleCheckboxChange('checkbox1')}>
          I've double-checked that values in admin are updated.
        </Checkbox>
        <br />
        <Checkbox checked={checkboxes.checkbox2} onChange={() => handleCheckboxChange('checkbox2')}>
          I've waited for 30 minutes after making those changes, and I've reloaded the Streamline
          web page and still can't see an update there.
        </Checkbox>
        <br />
        <Checkbox checked={checkboxes.checkbox3} onChange={() => handleCheckboxChange('checkbox3')}>
          I've notified developers about this issue in Slack: I've told them what I've updated and
          in which places and what I expect to appear where.
        </Checkbox>
      </Modal>
    </>
  )
}

export default PublishChangesButton
