import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './app/store'
import { history } from './app/history'

ReactDOM.render(
  /* Deactivating strict mode until antd fixes the 'Using <[some components]> results in "findDOMNode is deprecated in StrictMode"' error
    More info here: https://github.com/ant-design/ant-design/issues/22493 
  */
  // <React.StrictMode>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
