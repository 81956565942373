/* eslint-disable no-param-reassign */
import axios from 'axios'
import { getAuthenticationToken } from '../auth/authentication'
import configEnv from '../config'

const api = axios.create({ baseURL: configEnv.API_URL })

api.interceptors.request.use(async (config) => {
  config.headers = {
    'X-Requester': 'Streamline_Admin',
    'X-API-Language': 'EN',
    authorization: '',
  }

  const token = await getAuthenticationToken()

  if (token) {
    config.headers.authorization = token
  }

  return config
})

export default api
