import { LoadingOutlined } from '@ant-design/icons'
import { Progress } from 'antd'
import React, { FunctionComponent, useEffect, useState } from 'react'

interface UploadProgressProps {
  resultsReceived: boolean
  fileSize: number
}

const UploadProgress: FunctionComponent<UploadProgressProps> = ({ resultsReceived, fileSize }) => {
  const [estimatedTime, setEstimatedTime] = useState(0)
  const [percentageEstimateCompletion, setPercentageEstimateCompletion] = useState(0)

  /* This is just a raw calculation based on the file size in order to give some feedback to the user 
  - This is not an accurate representation of the proggress but it's a progress based on the estimated time to complete
  - It's using a calculation considering 3562 bytes per second - this value was set based on experimentation from the time that it took to complete previous importations */
  useEffect(() => {
    setEstimatedTime(fileSize / 3562)
    setPercentageEstimateCompletion(0)
  }, [fileSize])

  useEffect(() => {
    const timeoutID = setInterval(() => {
      let percentage = +(100 / estimatedTime + percentageEstimateCompletion).toFixed(2)
      /* If estimated percentage is greater than 99 - but we didn't receive success from api then keep it at 99  */
      if (percentage >= 99 && !resultsReceived) {
        percentage = 99
      }
      setPercentageEstimateCompletion(percentage)
    }, 1000)

    return () => window.clearTimeout(timeoutID)
  }, [percentageEstimateCompletion, estimatedTime, resultsReceived])

  return (
    <div>
      <LoadingOutlined />
      <p>
        {`Uploading - The estimated time for completion is around ${Math.round(
          estimatedTime / 60
        )} minutes, you'll receive the results by email - Feel free to close this window`}
      </p>
      <Progress percent={percentageEstimateCompletion} />
    </div>
  )
}

export default UploadProgress
