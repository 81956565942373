import { ExclamationCircleOutlined } from '@ant-design/icons'
import { message, Modal } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch } from 'react-redux'
import useAxios from '../../../../app/api/apiHook'
import * as assetsSlice from '../../assetsSlice'
import { Subcategory } from '../../assetsSlice'
import styles from './SubcategoryRemoveModal.module.scss'

interface SubcategoryRemoveModalProps {
  subcategory: Subcategory
  onCancel(): void
}

const SubcategoryRemoveModal: FunctionComponent<SubcategoryRemoveModalProps> = ({
  subcategory,
  onCancel,
}) => {
  const [deleting, setIsDeleting] = useState(false)

  const dispatch = useDispatch()

  const [, deleteSubcategory] = useAxios(
    {
      url: `/v4/subcategories/${subcategory?.hash}`,
      method: 'DELETE',
    },
    { manual: true }
  )

  const handleCloseDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: 'subcategory', data: null }))
  }

  const handleDeleteIcon = async () => {
    try {
      setIsDeleting(true)
      await deleteSubcategory()
      dispatch(assetsSlice.actions.removeSubcategoryOnList(subcategory.hash))
      setIsDeleting(false)
      handleCloseDrawer()
      message.success('Subcategory and icons removed successfully!')
    } catch (error) {
      setIsDeleting(false)
      console.error(error)
      message.error('The subcategory and icons were not removed, please try again')
    }
  }

  return (
    <Modal
      closable={false}
      visible
      okButtonProps={{ danger: true }}
      onOk={handleDeleteIcon}
      onCancel={onCancel}
      okText="Delete"
      confirmLoading={deleting}
      title="Caution!"
    >
      <p>
        <ExclamationCircleOutlined className={styles.deleteModalIcon} />
        Are you sure you want to
        <span className={styles.deleteWord}>
          {` delete the "${subcategory.name}" subcategory and all the icons related to it`}
        </span>
        ?
      </p>
    </Modal>
  )
}

export default SubcategoryRemoveModal
