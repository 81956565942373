import api from '../../../app/api/api'
import { Subcategory } from '../assetsSlice'

const updateSubcategoriesOrder = async (subcategories: Subcategory[]) =>
  api.patch<Subcategory[]>('/v4/subcategories/order', {
    subcategories,
  })

const exportedObject = {
  updateSubcategoriesOrder,
}

export default exportedObject
