import { Form, Input, InputNumber, Switch } from 'antd'
import React, { FunctionComponent } from 'react'

const FamilyGroupFormLeftColumn: FunctionComponent = () => (
  <>
    <Form.Item
      label="Name"
      name="name"
      rules={[
        {
          required: true,
          message: 'Please input a name!',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Description"
      name="description"
      rules={[
        {
          required: false,
          max: 350,
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Subtitle"
      name="subtitle"
      rules={[
        {
          required: false,
          max: 350,
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Site URL"
      name="siteUrl"
      rules={[
        {
          required: false,
          max: 255,
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Slug"
      name="slug"
      rules={[
        {
          required: true,
          message: 'Please input a slug!',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Color"
      name="color"
      rules={[
        {
          required: true,
          message: 'Please input a color!',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Sorting number"
      name="order"
      rules={[
        {
          required: true,
          message: 'Please input a subcategory order!',
        },
      ]}
    >
      <InputNumber min={1} max={999} />
    </Form.Item>

    <Form.Item label="Available For Sale" name="isAvailableForSale" valuePropName="checked">
      <Switch />
    </Form.Item>

    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        prevValues.isAvailableForSale !== currentValues.isAvailableForSale
      }
    >
      {({ getFieldValue }) => {
        const isAvailableForSale = getFieldValue('isAvailableForSale')

        return (
          <Form.Item
            label="Stripe One-Time Price Id"
            name="stripeOneTimePriceId"
            rules={[
              {
                required: isAvailableForSale,
                message: 'Please specify the Stripe One-Time Price Id!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        )
      }}
    </Form.Item>
  </>
)

export default FamilyGroupFormLeftColumn
