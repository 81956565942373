import { Button } from 'antd'
import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Routes } from '../../../../app/routes/app-router'
import HeaderBig from '../../../../components/HeaderBig/HeaderBig'
import * as usersSlice from '../../usersSlice'
import CopyPasswordRecoverUrlButton from './copy-password-recover-url-button/CopyPasswordRecoverUrlButton'
import DeleteUserButton from './delete-user-button/DeleteUserButton'
import SendPasswordButton from './send-password-button/SendPasswordButton'

interface UserDetailsHeaderProps {
  isEditing: boolean
  isSaving: boolean
}

const UserDetailsHeader: FunctionComponent<UserDetailsHeaderProps> = ({ isEditing, isSaving }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(usersSlice.selectUser)

  const handleCancel = () => {
    history.push(Routes.USERS)
    dispatch(usersSlice.actions.selectedUser(null))
  }

  return (
    <HeaderBig
      leftCol={
        <>
          {isEditing && (
            <>
              <SendPasswordButton email={user?.email || ''} />
              <CopyPasswordRecoverUrlButton userHash={user?.hash || ''} />
              <DeleteUserButton userHash={user?.hash || ''} />
            </>
          )}
        </>
      }
      rightCol={
        <>
          <Button onClick={() => handleCancel()}>Cancel</Button>
          <Button type="primary" htmlType="submit" loading={isSaving}>
            Save
          </Button>
        </>
      }
    />
  )
}

export default UserDetailsHeader
