import { Drawer, Form, Input, InputNumber, message, Switch } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../app/api/apiHook'
import handleError from '../../../app/api/handleError'
import AntFormItemExplain from '../../../components/AntFormItemExplain/AntFormItemExplain'
import DrawerHeader from '../../../components/DrawerHeader/DrawerHeader'
import DrawerSubtitle from '../../../components/DrawerSubtitle/DrawerSubtitle'
import DrawerTwoColumns from '../../../components/DrawerTwoColumns/DrawerTwoColumns'
import * as assetsSlice from '../assetsSlice'
import { Subcategory } from '../assetsSlice'
import SubcategoryRemoveModal from './subcategory-remove-modal/SubcategoryRemoveModal'

const SubcategoryDrawer: FunctionComponent = () => {
  const [form] = Form.useForm()
  const drawer = useSelector(assetsSlice.selectDrawer)
  const category = useSelector(assetsSlice.selectCategory)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const subcategory = drawer.data as Subcategory

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const [, editSubcategory] = useAxios(
    {
      url: `/v4/subcategories/${subcategory?.hash}`,
      method: 'PATCH',
    },
    { manual: true }
  )

  const [, createSubcategory] = useAxios(
    {
      url: '/v4/subcategories',
      method: 'POST',
    },
    { manual: true }
  )

  const handleCloseDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: 'subcategory', data: null }))
  }

  const onFinish = async (values: any) => {
    const { name, visible, order } = values
    try {
      setLoading(true)
      let response
      if (subcategory?.hash) {
        response = await editSubcategory({
          data: {
            name,
            visible,
            categoryHash: category?.hash,
            order,
          },
        })
        dispatch(assetsSlice.actions.updateSubcategoryOnList(response.data))
      } else {
        response = await createSubcategory({
          data: {
            name,
            visible,
            categoryHash: category?.hash,
            order,
          },
        })
        dispatch(assetsSlice.actions.addSubcategoryOnList(response.data))
      }
      setLoading(false)
      message.success('Subcategory saved successfully!')
      handleCloseDrawer()
    } catch (error) {
      setLoading(false)
      const responseError = handleError(error)

      message.error(`Something went wrong: ${responseError}`)
    }
  }

  const leftColumnContent = () => (
    <>
      <DrawerSubtitle text="Description" />
      <Form.Item
        label="Subcategory Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input a name!',
          },
        ]}
      >
        <Input />
      </Form.Item>
    </>
  )

  const rightColumnContent = () => (
    <>
      <DrawerSubtitle text="Options" />
      <Form.Item label="Show to users" name="visible" valuePropName="checked">
        <Switch defaultChecked />
      </Form.Item>

      <DrawerSubtitle text="Preferences" />
      <Form.Item
        label="Sorting number"
        name="order"
        rules={[
          {
            required: true,
            message: 'Please input a subcategory order!',
          },
        ]}
      >
        <InputNumber min={1} max={999} />
      </Form.Item>
      <AntFormItemExplain
        type="text"
        message="Help to sort subcategories. 01 = first in the list"
      />
    </>
  )

  return (
    <>
      <Drawer
        destroyOnClose
        width={640}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() =>
          dispatch(
            assetsSlice.actions.toggleDrawer({ visible: false, type: 'subcategory', data: null })
          )
        }
        visible={drawer.type === 'subcategory' && drawer.visible}
      >
        <Form
          form={form}
          name="subcategory-form"
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
          initialValues={{
            name: subcategory?.name,
            order: subcategory?.order || 1,
            visible: subcategory ? subcategory.visible : true,
          }}
        >
          <DrawerHeader
            showSaveButton
            isSaving={loading}
            title={drawer.data?.hash ? 'Modify Subcategory' : 'New Subcategory'}
            onClickCancel={() => handleCloseDrawer()}
            showDeleteButton={!!subcategory?.hash}
            onClickDelete={() => setIsDeleteModalVisible(true)}
          />

          <DrawerTwoColumns leftCol={leftColumnContent()} rightCol={rightColumnContent()} />
        </Form>
      </Drawer>
      {isDeleteModalVisible && (
        <SubcategoryRemoveModal
          onCancel={() => setIsDeleteModalVisible(false)}
          subcategory={subcategory}
        />
      )}
    </>
  )
}

export default SubcategoryDrawer
