import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { signInWithEmailAndPassword } from 'firebase/auth'
import auth from '../../app/auth/firebaseAuthentication'

import handleError from '../../app/api/handleError'
import * as userSlice from '../../app/auth/authSlice'
import { Routes } from '../../app/routes/app-router'
import AntFormItemExplain from '../../components/AntFormItemExplain/AntFormItemExplain'
import { selectIsLoggedIn } from '../../app/auth/authSlice'
import styles from './Signin.module.scss'

const SigninPage: FunctionComponent = () => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [, forceUpdate] = useState({})
  const loggedIn = useSelector(selectIsLoggedIn)
  const dispatch = useDispatch()
  const history = useHistory()

  const onFinish = async (values: any) => {
    firebaseAuthentication(values)
  }

  const firebaseAuthentication = async (credentials: { email: string; password: string }) => {
    try {
      setLoading(true)
      await signInWithEmailAndPassword(auth, credentials.email, credentials.password)

      dispatch(userSlice.checkAuthorizationToken())
      setLoading(false)

      history.push(Routes.ASSETS)
    } catch (error) {
      setLoading(false)
      const responseError = handleError(error)
      setErrorMessage(responseError)
    }
  }

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({})
  }, [])

  if (loggedIn) {
    return <Redirect to="/assets" />
  }

  return (
    <Content>
      <div className={styles.root}>
        <Card className={styles.box}>
          <h1 className={styles.title}>Sign in</h1>
          <p className={styles.subtitle}>Use your streamline admin account</p>
          <Form
            form={form}
            layout="vertical"
            name="signin-form"
            requiredMark={false}
            onFinish={onFinish}
            onFieldsChange={() => setErrorMessage('')}
          >
            <Form.Item
              label="E-mail:"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please input a valid e-mail!',
                },
              ]}
            >
              <Input size="large" prefix={<UserOutlined className={styles.iconInput} />} />
            </Form.Item>

            <Form.Item
              label="Password:"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password size="large" prefix={<LockOutlined className={styles.iconInput} />} />
            </Form.Item>

            <Form.Item shouldUpdate>
              {() => (
                <>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form.getFieldsError().filter(({ errors }) => errors.length).length
                    }
                    className={styles.loginButton}
                  >
                    Sign in
                  </Button>
                  <AntFormItemExplain message={errorMessage} type="error" />
                </>
              )}
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Content>
  )
}

export default SigninPage
