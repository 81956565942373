import { DeleteOutlined } from '@ant-design/icons'
import { Button, Col, Collapse, Row, Tooltip } from 'antd'
import React, { FunctionComponent, useEffect, useState } from 'react'
import * as assetsSlice from '../../../assetsSlice'
import IconItemUpload from '../../../icons-panel/icon-item/icon-item-upload/IconItemUpload'
import IconsRemoveModal from '../../../icons-remove-modal/IconsRemoveModal'
import ColorRepaintIconLink from '../../color-scan-form/color-repaint-results/color-repaint-icon-link/ColorRepaintIconLink'
import styles from './ColorScanBrokenAsset.module.scss'

interface IconItemProps {
  icon: assetsSlice.IconInAssetsListDto
  onDeleteSuccess(icon: assetsSlice.IconInAssetsListDto): void
}

const ColorScanBrokenAsset: FunctionComponent<IconItemProps> = ({
  icon: iconInitialValue,
  onDeleteSuccess,
}) => {
  const [icon, setIcon] = useState(iconInitialValue)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  useEffect(() => {
    setIcon(iconInitialValue)
  }, [iconInitialValue])

  return (
    <>
      <div className={styles.root}>
        <ColorRepaintIconLink icon={icon} />

        <Row wrap={false}>
          <Col flex="72px" className={styles.col}>
            <IconItemUpload
              iconHash={icon.hash}
              familySlug={icon.family.slug}
              categorySlug={icon.category.slug}
              subcategorySlug={icon.subcategory.slug}
              subcategoryHash={icon.subcategory.hash}
              svg={icon.svg}
              onComplete={(i) => {
                setIcon({ ...icon, svg: i.svg, name: i.name })
              }}
            />
          </Col>
          <Col flex="auto" className={styles.col}>
            <p>{icon.name}</p>
            <Collapse ghost>
              <Collapse.Panel header="SVG Source" key="1">
                <p>{icon.svg}</p>
              </Collapse.Panel>
            </Collapse>
          </Col>
          <Col flex="72px" className={styles.col}>
            <Tooltip title="remove">
              <Button
                type="text"
                icon={
                  <DeleteOutlined
                    className={styles.iconAction}
                    onClick={() => setIsDeleteModalVisible(true)}
                  />
                }
              />
            </Tooltip>
          </Col>
        </Row>
      </div>
      {isDeleteModalVisible && (
        <IconsRemoveModal
          icons={[{ ...icon }]}
          onCancel={() => setIsDeleteModalVisible(false)}
          onSuccess={() => {
            setIsDeleteModalVisible(false)
            onDeleteSuccess(icon)
          }}
        />
      )}
    </>
  )
}

export default ColorScanBrokenAsset
