import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Input, message, Modal } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../../app/api/apiHook'
import * as assetsSlice from '../../assetsSlice'
import { Family } from '../../assetsSlice'
import styles from './FamilyRemoveModal.module.scss'

interface FamilyRemoveModalProps {
  family: Family
  onCancel(): void
}

const FamilyRemoveModal: FunctionComponent<FamilyRemoveModalProps> = ({ family, onCancel }) => {
  const dispatch = useDispatch()

  const selectedFamily = useSelector(assetsSlice.selectFamily)

  const [deleting, setIsDeleting] = useState(false)
  const [familyNameConfirmation, setFamilyNameConfirmation] = useState('')

  const [, deleteSubcategory] = useAxios(
    {
      url: `/v4/families/${family?.hash}`,
      method: 'DELETE',
    },
    { manual: true }
  )

  const handleCloseDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: 'category', data: null }))
  }

  const handleDeleteIcon = async () => {
    try {
      if (familyNameConfirmation === selectedFamily?.name) {
        setIsDeleting(true)
        await deleteSubcategory()
        dispatch(assetsSlice.actions.removeFamilyOnList(family.hash))
        setIsDeleting(false)
        handleCloseDrawer()
        message.success('Family, categories, subcategories and icons removed successfully!')
      } else {
        message.warning('Please write the family name correctly to confirm the exclusion!')
      }
    } catch (error) {
      setIsDeleting(false)
      console.error(error)
      message.error('The family, categories and icons were not removed, please try again')
    }
  }

  return (
    <Modal
      closable={false}
      visible
      okButtonProps={{ danger: true }}
      onOk={handleDeleteIcon}
      onCancel={onCancel}
      okText="Delete"
      confirmLoading={deleting}
      title="Caution!"
    >
      <p>
        <ExclamationCircleOutlined className={styles.deleteModalIcon} />
        Are you sure you want to
        <span className={styles.deleteWord}>
          {` delete the "${family.name}" family and all the categories, subcategories and icons related to it`}
        </span>
        ?
      </p>
      <br />
      <p>
        Please write <b style={{ whiteSpace: 'pre' }}>{selectedFamily?.name}</b> on the input below
        to confirm the exclusion:
      </p>
      <Input onChange={(e) => setFamilyNameConfirmation(e.target.value)} />
    </Modal>
  )
}

export default FamilyRemoveModal
