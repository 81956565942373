import { Drawer, Form } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DrawerHeader from '../../../components/DrawerHeader/DrawerHeader'
import * as assetsSlice from '../assetsSlice'
import { Family } from '../assetsSlice'
import ColorScanForm from './color-scan-form/ColorScanForm'

const ColorScanDrawer: FunctionComponent = () => {
  const [form] = Form.useForm()
  const drawer = useSelector(assetsSlice.selectDrawer)
  const family = drawer.data as Family

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const handleCloseDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: 'color-scan', data: null }))
  }

  const handleSubmit = async () => {
    await form.submit()
  }

  return (
    <>
      <Drawer
        destroyOnClose
        width={720}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() =>
          dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: 'color-scan' }))
        }
        visible={drawer.type === 'color-scan' && drawer.visible}
      >
        <DrawerHeader
          showSaveButton={false}
          isSaving={loading}
          title="Color Scan"
          onClickCancel={() => handleCloseDrawer()}
          onClickSave={handleSubmit}
          showDeleteButton={false}
        />
        <ColorScanForm
          form={form}
          family={family}
          onSubmit={() => setLoading(true)}
          onSubmitError={() => setLoading(false)}
        />
      </Drawer>
    </>
  )
}

export default ColorScanDrawer
