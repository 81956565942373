import axios from 'axios'
import React, { FunctionComponent, useState } from 'react'
import { useSelector } from 'react-redux'
import { FeedbackMessageType } from '../../../../../app/types'
import AntFormItemExplain from '../../../../../components/AntFormItemExplain/AntFormItemExplain'
import IconUpload from '../../../../../components/IconUpload/IconUpload'
import * as assetsSlice from '../../../assetsSlice'
import { Icon } from '../../../assetsSlice'

interface IconItemUploadProps {
  iconHash: string
  svg: string
  familySlug: string
  categorySlug: string
  subcategorySlug: string
  subcategoryHash: string
  onComplete(icon: Icon): void
}

const IconItemUpload: FunctionComponent<IconItemUploadProps> = ({
  iconHash,
  svg,
  familySlug,
  categorySlug,
  subcategorySlug,
  subcategoryHash,
  onComplete,
}) => {
  const previewSize = useSelector(assetsSlice.selectPreviewSize)
  const [feedbackStatus, setFeedbackStatus] = useState<{
    type: FeedbackMessageType
    message: string
  }>({
    type: 'text',
    message: '',
  })

  const handleUploadComplete = async (icon: Icon) => {
    try {
      onComplete(icon)
      setFeedbackStatus({ type: 'success', message: 'Icon image updated!' })
    } catch (error) {
      if (!axios.isCancel(error)) {
        setFeedbackStatus({ type: 'error', message: 'Failed to save, please try again.' })
        console.error(error)
      }
    }
  }

  const handleUploadFailed = (error: string) => {
    setFeedbackStatus({ type: 'error', message: error || 'Failed to save, please try again.' })
  }

  return (
    <>
      <div style={{ width: previewSize, height: previewSize }}>
        <IconUpload
          iconHash={iconHash}
          familySlug={familySlug}
          categorySlug={categorySlug}
          subcategorySlug={subcategorySlug}
          subcategoryHash={subcategoryHash}
          svg={svg}
          onUploadComplete={(icon) => handleUploadComplete(icon)}
          onUploadFailed={handleUploadFailed}
        />
      </div>
      <AntFormItemExplain message={feedbackStatus.message} type={feedbackStatus.type} />
    </>
  )
}

export default IconItemUpload
