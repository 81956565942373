import { Button, Form, Input, message } from 'antd'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useAxios from '../../../../app/api/apiHook'
import { usersSlice } from '../../usersSlice'
import styles from './UsersSearch.module.scss'

const UsersSearch: FunctionComponent = () => {
  const dispatch = useDispatch()
  const [searchQuery, setSearchQuery] = useState('onlyAdmin=true')

  const [{ data, loading, error }, getUsersSearch] = useAxios(
    {
      url: `/v4/users?${searchQuery}`,
      method: 'GET',
    },
    { manual: true }
  )

  const onFinish = async (values: any) => {
    setSearchQuery(buildSearchQuery(values))
  }

  const buildSearchQuery = (values: any): string => {
    const encodedSearchTerms = encodeURIComponent(values.searchTerms.toString().trim())
    return `q=${encodedSearchTerms}&onlyAdmin=${encodedSearchTerms ? 'false' : 'true'}`
  }

  useEffect(() => {
    if (data) {
      dispatch(usersSlice.actions.fetchUsersSuccess(data))
    }
  }, [data, dispatch])

  useEffect(() => {
    getUsersSearch()
  }, [getUsersSearch, searchQuery])

  if (error) {
    message.error('Something wrong happened when loading the users, please try again!')
  }

  return (
    <Form
      name="search-user-form"
      onFinish={onFinish}
      layout="inline"
      requiredMark="optional"
      initialValues={{ searchTerms: '' }}
    >
      <Form.Item
        name="searchTerms"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input className={styles.searchInput} size="large" placeholder="Search users..." />
      </Form.Item>

      <Button size="large" type="primary" htmlType="submit" loading={loading}>
        Search
      </Button>
    </Form>
  )
}

export default UsersSearch
