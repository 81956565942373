import Layout from 'antd/lib/layout/layout'
import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import './App.scss'
import { onAuthStateChanged } from 'firebase/auth'
import auth from './app/auth/firebaseAuthentication'
import * as userSlice from './app/auth/authSlice'
import AppRouter from './app/routes/app-router'

const App: FunctionComponent = () => {
  const dispatch = useDispatch()
  const validatingToken = useSelector(userSlice.selectIsValidatingToken)

  onAuthStateChanged(auth, () => {
    dispatch(userSlice.checkAuthorizationToken())
  })

  if (validatingToken) {
    return <p> Validating token </p>
  }
  return (
    <Layout>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </Layout>
  )
}

export default App
