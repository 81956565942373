import { format } from 'date-fns'
import { Button, Drawer, List, message, Spin, Tooltip, Typography, Collapse, Row } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../app/api/apiHook'
import handleError from '../../../app/api/handleError'
import DrawerHeader from '../../../components/DrawerHeader/DrawerHeader'
import * as assetsSlice from '../assetsSlice'
import styles from './FixTagsDrawer.module.scss'

const { Text } = Typography

export type FixedIconResponse = {
  previousSVG: string
  fixedSVG: string
  status: any
  path: string
  message?: string
}

export type FixTagsResponse = {
  oldTags: string[]
  newTags: string[]
  oldAutoTags: string[]
  newAutotags: string[]
  iconSlug: string
  iconName: string
}

const FixTagsDrawer: FunctionComponent = () => {
  const drawerType = 'fix-tags'
  const drawer = useSelector(assetsSlice.selectDrawer)
  const [errorText, setErrorText] = useState<string | null>(null)
  const [isSaveEnabled, setIsSaveEnabled] = useState(false)

  const dispatch = useDispatch()

  const handleCloseDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: drawerType, data: null }))
  }

  const [{ data, loading, error }, fixTags] = useAxios<FixTagsResponse[]>(
    {
      url: `/v4/preferences/fix-tags`,
      method: 'PATCH',
    },
    { manual: true, autoCancel: false }
  )

  const handleFix = async ({ simulation }: { simulation: boolean }) => {
    try {
      setErrorText(null)

      await fixTags({ params: { simulation } })

      setIsSaveEnabled(true)

      message.success(`Fix tags was successful!`, 10)
    } catch (e) {
      const responseError = handleError(error)
      setErrorText(responseError)
    }
  }

  function getDifferentTags(array1: string[], array2: string[]): string[] {
    return array1.filter((item) => !array2.includes(item))
  }

  return (
    <>
      <Drawer
        destroyOnClose
        width={720}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() => handleCloseDrawer()}
        visible={drawer.type === drawerType && drawer.visible}
      >
        <DrawerHeader
          showSaveButton={false}
          isSaving={loading}
          title="Automatically fix tags"
          onClickCancel={() => handleCloseDrawer()}
          showDeleteButton={false}
        />

        <Spin spinning={loading}>
          <>
            {data && data.length > 0 && (
              <>
                <p style={{ color: '#3f8600' }}>Successfull fixed tags</p>

                <List
                  itemLayout="vertical"
                  size="large"
                  pagination={{
                    pageSize: 10,
                    simple: true,
                  }}
                  dataSource={data || []}
                  renderItem={(result) => (
                    <List.Item key={result.iconSlug}>
                      <h3>{result.iconName}</h3>
                      Tags:
                      <div className={styles.compareSection}>
                        <div>
                          <h4>old tags:</h4>
                          {getDifferentTags(result.oldTags, result.newTags).map((tag) => (
                            <p>{tag}</p>
                          ))}
                        </div>
                        <div>
                          <h4>new tags:</h4>
                          {getDifferentTags(result.newTags, result.oldTags).map((tag) => (
                            <p>{tag}</p>
                          ))}
                        </div>
                      </div>
                      Auto Tags:
                      <div className={styles.compareSection}>
                        <div>
                          <h4>old tags:</h4>
                          {getDifferentTags(result.oldAutoTags, result.newAutotags).map((tag) => (
                            <p>{tag}</p>
                          ))}
                        </div>
                        <div>
                          <h4>new tags:</h4>
                          {getDifferentTags(result.newAutotags, result.oldAutoTags).map((tag) => (
                            <p>{tag}</p>
                          ))}
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              </>
            )}

            <h4>
              This will fix all tags that contains a dash, replacing it with a space. It will trim
              the tag and lowercase it too in case it's not already
            </h4>

            <Tooltip title="Run a fix simulation without saving in the database.">
              <Button
                htmlType="button"
                size="large"
                onClick={() => handleFix({ simulation: true })}
              >
                Run simulation
              </Button>
            </Tooltip>

            <Tooltip title="Save the changes in the database.">
              <Button
                htmlType="button"
                size="large"
                onClick={() => handleFix({ simulation: false })}
                className={styles.button}
                disabled={!isSaveEnabled}
              >
                Save in the database
              </Button>
            </Tooltip>
          </>
        </Spin>
        {errorText && <Text type="danger">{errorText}</Text>}
      </Drawer>
    </>
  )
}

export default FixTagsDrawer
