import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { FunctionComponent } from 'react'
import styles from './DrawerHeader.module.scss'

interface DrawerHeaderProps {
  title: string
  isSaving: boolean
  saveButtonTitle?: string
  showDeleteButton?: boolean
  showSaveButton: boolean
  extraContent?: React.ReactNode
  onClickSave?(): void
  onClickCancel(): void
  onClickDelete?(): void
}

const DrawerHeader: FunctionComponent<DrawerHeaderProps> = ({
  title,
  isSaving: loadingSave,
  saveButtonTitle = 'Save',
  showDeleteButton,
  showSaveButton,
  onClickSave,
  onClickCancel,
  onClickDelete,
  extraContent,
}) => (
  <div className={styles.root}>
    <div className={styles.left}>
      <h2>{title}</h2>
      {showDeleteButton && (
        <Button onClick={onClickDelete} danger type="text" icon={<DeleteOutlined />}>
          Delete
        </Button>
      )}
      {extraContent}
    </div>
    <div className={styles.right}>
      <Button onClick={onClickCancel} className={styles.button}>
        Close
      </Button>
      {showSaveButton && (
        <Button
          loading={loadingSave}
          onClick={onClickSave}
          type="primary"
          className={styles.button}
          htmlType="submit"
        >
          {saveButtonTitle}
        </Button>
      )}
    </div>
  </div>
)

export default DrawerHeader
