import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { Form, Input } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import { UploadFile } from 'antd/lib/upload/interface'
import React, { FunctionComponent, useState } from 'react'
import { getAuthenticationToken } from '../../../../../app/auth/authentication'
import envConfig from '../../../../../app/config'
import styles from './FamilyFormUploadCover.module.scss'

interface FamilyFormUploadCoverProps {
  defaultImage: string
  onUploadComplete(url: string): void
}

const FamilyFormUploadCover: FunctionComponent<FamilyFormUploadCoverProps> = ({
  onUploadComplete,
  defaultImage,
}) => {
  const [uploading, setUploading] = useState(false)
  const [imagePublicId, setImageUrl] = useState(defaultImage)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [uploadToken, setUploadToken] = useState<string>('')

  const handleUploadComplete = (url: string, list: UploadFile[]) => {
    setImageUrl(url)
    setUploading(false)
    setFileList(list)
    return onUploadComplete(url)
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const getUploadUrl = async () => {
    const token = await getAuthenticationToken()

    if (token) {
      setUploadToken(token)
    }

    return `${envConfig.API_URL}/v4/families/upload`
  }

  return (
    <div className={styles.draggerArea}>
      <Form.Item name="cover" className={styles.hiddenCoverField}>
        <Input />
      </Form.Item>
      <Form.Item
        valuePropName="fileList"
        getValueFromEvent={normFile}
        name="fileList"
        rules={[
          {
            required: !imagePublicId,
            message: 'Please upload a cover!',
          },
        ]}
      >
        <Dragger
          name="familyCover"
          multiple={false}
          action={getUploadUrl}
          headers={{ Authorization: uploadToken }}
          onChange={(info) => {
            if (info.file.status === 'uploading') {
              setUploading(true)
            }
            if (info.file.status === 'done') {
              handleUploadComplete(info.file.response.coverUrl, [
                info.fileList[info.fileList.length - 1],
              ])
            }
          }}
          showUploadList={false}
          accept=".svg"
          listType="picture-card"
          fileList={fileList}
        >
          {!imagePublicId ? (
            <>
              <p className="ant-upload-drag-family">
                <UploadOutlined width={18} />
              </p>
              <p className={styles.draggerText}>Click or drag file to this area to upload</p>
              <p className={styles.draggerTextSmall}>File type accepted : .svg</p>
            </>
          ) : (
            <>
              {uploading ? (
                <div className={styles.uploadingFamily}>
                  <LoadingOutlined />
                  <p>Uploading family</p>
                </div>
              ) : (
                <img src={imagePublicId} alt="Family" width={100} height={100} />
              )}
            </>
          )}
        </Dragger>
      </Form.Item>
    </div>
  )
}

export default FamilyFormUploadCover
