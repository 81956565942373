import { DislikeOutlined, LikeOutlined, MinusOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  List,
  message,
  Row,
  Spin,
  Statistic,
  Tooltip,
  Typography,
  Result,
} from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../app/api/apiHook'
import handleError from '../../../app/api/handleError'
import DrawerHeader from '../../../components/DrawerHeader/DrawerHeader'
import * as assetsSlice from '../../assets/assetsSlice'
import ChangesListFix from '../changes-list-fix/ChangesListFix'
import SVGComparison from '../fix-restore-drawer/SVGComparison'
import styles from './FixFamilyDrawer.module.scss'
import FixingFamiliesStatusEnum from './types'

const { Text } = Typography

export enum FixResultStatusEnum {
  ERROR = 'ERROR',
  NOT_MODIFIED = 'NOT_MODIFIED',
  SUCCESS = 'SUCCESS',
}

export type FixIconResult = {
  previousSVG: string
  fixedSVG: string
  status: FixResultStatusEnum
  path: string
  message?: string
}

export type FixFamilyResult = {
  familySlug: string
  response: FixIconResult[]
  responseErrors: FixIconResult[]
  allIconsFixed: boolean
  errorPercentage: number
  fixingTimeout: boolean
  simulation: boolean
  status: FixingFamiliesStatusEnum
}

const FixFamilyDrawer: FunctionComponent = () => {
  const drawerType = 'fix-family'
  const [form] = Form.useForm()
  const drawer = useSelector(assetsSlice.selectDrawer)
  // At first the fix is in simulation mode, then the admin confirms the change
  const [isSimulation, setIsSimulation] = useState(true)
  const [errorText, setErrorText] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [fixedSVGPreviews, setFixedSVGPreviews] = useState<
    | {
        previousSVG: string
        fixedSVG: string
        status: FixResultStatusEnum
        path: string
        message?: string
      }[]
    | null
  >(null)
  const family = useSelector(assetsSlice.selectFamily)

  const dispatch = useDispatch()

  const handleCloseDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: drawerType, data: null }))
  }

  const handleSubmit = async () => {
    await form.submit()
  }

  const [, fixIcon] = useAxios<{
    message: string
  }>(
    {
      url: `/v4/image-engine/fix-family/${family?.slug}?simulation=${isSimulation}`,
      method: 'PATCH',
    },
    { manual: true, autoCancel: false }
  )

  const [, getFixFamilyResult] = useAxios<FixFamilyResult>(
    {
      url: '/v4/image-engine/fix-family-results',
      method: 'GET',
    },
    { manual: true, autoCancel: false }
  )

  const handleFix = async (shouldSimulate: boolean) => {
    try {
      setLoading(true)
      setIsSimulation(shouldSimulate)
      setErrorText(null)

      const { data } = await fixIcon({ data: { isSimulation } })

      message.success(data.message)
    } catch (error) {
      // @TODO For some reason this functionality throws a cancel error when clicked
      // the second time. Cause is unknown, it should be fixed.
      if (axios.isCancel(error)) {
        message.warning('Please click again')
      } else {
        const responseError = handleError(error)
        message.error(`Could not fix an image`)
        setErrorText(responseError)
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateFixFamilyResult = async () => {
    const { data: fixFamilyResult } = await getFixFamilyResult()

    if (fixFamilyResult.familySlug !== family?.slug) {
      return
    }

    if (fixFamilyResult.status === FixingFamiliesStatusEnum.IN_PROGRESS) {
      setLoading(true)
      return
    }

    if (fixFamilyResult.status === FixingFamiliesStatusEnum.FINISHED) {
      setLoading(false)
    }

    // First error, then success, then not modified
    const sortedFixedSVGs = fixFamilyResult.response.sort(
      (a: { status: FixResultStatusEnum }, b: { status: FixResultStatusEnum }) => {
        const statusValue = (status: FixResultStatusEnum) => {
          switch (status) {
            case FixResultStatusEnum.ERROR:
              return 1
            case FixResultStatusEnum.SUCCESS:
              return 2
            default:
              return 3
          }
        }

        return statusValue(a.status) - statusValue(b.status)
      }
    )

    if (fixFamilyResult.simulation) {
      setFixedSVGPreviews(sortedFixedSVGs)
    } else {
      setFixedSVGPreviews(null)
      message.success(`Image's SVG has been fixed`)
    }
  }

  // If modal is open, we request the logs every few seconds
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined

    if (loading) {
      const fiveSeconds = 1000 * 5
      intervalId = setInterval(() => {
        updateFixFamilyResult()
      }, fiveSeconds)
    }

    return () => {
      if (intervalId) return clearInterval(intervalId)
      return undefined
    }
  }, [loading, updateFixFamilyResult])

  useEffect(() => {
    updateFixFamilyResult()
  }, [])

  if (!family) {
    return null
  }

  return (
    <>
      <Drawer
        destroyOnClose
        width={720}
        placement="right"
        closable={false}
        maskClosable
        onClose={() => handleCloseDrawer()}
        visible={drawer.type === drawerType && drawer.visible}
      >
        <DrawerHeader
          showSaveButton={false}
          isSaving={loading}
          title="Fix all images in a family"
          onClickCancel={() => handleCloseDrawer()}
          onClickSave={handleSubmit}
          showDeleteButton={false}
        />
        <h3>Here you can automatically fix all images SVG from a family.</h3>
        <p>
          Failed results will be shown first, then the successful ones, then the not modified ones.
        </p>
        <Spin spinning={loading}>
          {fixedSVGPreviews && fixedSVGPreviews.length > 0 ? (
            <>
              {fixedSVGPreviews.length ===
              fixedSVGPreviews.filter((svg) => svg.status === FixResultStatusEnum.NOT_MODIFIED)
                .length ? (
                <>
                  <Result
                    status="success"
                    title="All images are already fixed!"
                    subTitle="All images are already fixed for this family, there is nothing to do here."
                  />

                  <>
                    <h4>Fix SVG automatically again</h4>
                    <Tooltip title="Preview how the SVG will look like after the fix. No changes will be saved in the database when you click this.">
                      <Button htmlType="button" size="large" onClick={() => handleFix(true)}>
                        Preview SVG fix
                      </Button>
                    </Tooltip>
                  </>
                </>
              ) : (
                <>
                  <Row>
                    <Col span={8}>
                      <Card className={styles.card}>
                        <Statistic
                          title="Successful"
                          value={
                            fixedSVGPreviews.filter(
                              (svg) => svg.status === FixResultStatusEnum.SUCCESS
                            ).length
                          }
                          precision={0}
                          valueStyle={{ color: '#3f8600' }}
                          prefix={<LikeOutlined />}
                        />
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card className={styles.card}>
                        <Statistic
                          title="Not modified"
                          value={
                            fixedSVGPreviews.filter(
                              (svg) => svg.status === FixResultStatusEnum.NOT_MODIFIED
                            ).length
                          }
                          precision={0}
                          valueStyle={{ color: '#4e4d4d' }}
                          prefix={<MinusOutlined />}
                        />
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card className={styles.card}>
                        <Statistic
                          title="Failed"
                          value={
                            fixedSVGPreviews.filter(
                              (svg) => svg.status === FixResultStatusEnum.ERROR
                            ).length
                          }
                          precision={0}
                          valueStyle={{ color: '#cf1322' }}
                          prefix={<DislikeOutlined />}
                        />
                      </Card>
                    </Col>
                  </Row>
                  <p>{`Total results: ${fixedSVGPreviews.length}`}</p>
                  <List
                    itemLayout="vertical"
                    size="large"
                    pagination={{
                      pageSize: 10,
                      simple: true,
                    }}
                    dataSource={fixedSVGPreviews || []}
                    renderItem={(icon) => (
                      <List.Item
                        key={icon.fixedSVG}
                        className={icon.status === FixResultStatusEnum.ERROR ? styles.Error : ''}
                      >
                        <div>
                          <SVGComparison
                            firstSVG={icon.previousSVG}
                            firstTitle="Image's current SVG"
                            secondSVG={icon.fixedSVG}
                            secondTitle="Image's SVG after fixing"
                          />
                          {icon.status === FixResultStatusEnum.NOT_MODIFIED && (
                            <Text type="secondary">
                              Image's SVG after fixing will stay the same, so it is already fixed.
                            </Text>
                          )}
                          {icon.message && <>{icon.message}</>}
                          <br />
                          <a
                            href={`https://streamlinehq.com/${icon.path}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {icon.path}
                          </a>
                        </div>
                      </List.Item>
                    )}
                  />
                  <Tooltip title="Save these changes in the database. If anything breaks you can restore this image's SVG to its original state above.">
                    <Button htmlType="button" size="large" onClick={() => handleFix(false)}>
                      Save fixed SVG
                    </Button>
                  </Tooltip>
                </>
              )}
            </>
          ) : (
            <>
              <h4>Fix SVG automatically</h4>
              <ChangesListFix strokeAllowed={family.strokeAllowed} />
              <Tooltip title="Preview how the SVG will look like after the fix. No changes will be saved in the database when you click this.">
                <Button htmlType="button" size="large" onClick={() => handleFix(true)}>
                  Preview SVG fix
                </Button>
              </Tooltip>
            </>
          )}
        </Spin>
        {errorText && <Text type="danger">{errorText}</Text>}
      </Drawer>
    </>
  )
}

export default FixFamilyDrawer
