import React, { FunctionComponent } from 'react'
import styles from './UsersPage.module.scss'
import Users from '../../features/users/Users'

const UsersPage: FunctionComponent = () => (
  <div className={styles.root}>
    <Users />
  </div>
)

export default UsersPage
