import { ExclamationCircleOutlined } from '@ant-design/icons'
import { message, Modal } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useAxios from '../../../app/api/apiHook'
import * as assetsSlice from '../assetsSlice'
import styles from './IconsRemoveModal.module.scss'

interface IconsRemoveModalProps {
  icons: { hash: string; name: string }[]
  onCancel(): void
  onSuccess(): void
}

const IconsRemoveModal: FunctionComponent<IconsRemoveModalProps> = ({
  icons,
  onCancel,
  onSuccess,
}) => {
  const family = useSelector(assetsSlice.selectFamily)
  const [deleting, setIsDeleting] = useState(false)

  const dispatch = useDispatch()

  const getIconsHashes = (): string => icons.map((icon) => icon.hash).toString()

  const [, deleteIcons] = useAxios(
    {
      url: `/v4/icons/${getIconsHashes()}?familyHash=${family?.hash}`,
      method: 'DELETE',
    },
    { manual: true }
  )

  const handleDeleteIcon = async () => {
    try {
      setIsDeleting(true)
      await deleteIcons()
      dispatch(assetsSlice.actions.removeIconsOnList(icons))
      setIsDeleting(false)
      onSuccess()
      message.success(
        `${icons.length > 1 ? `${icons.length} icons` : 'Icon'} removed successfully!`
      )
    } catch (error) {
      setIsDeleting(false)
      console.error(error)
      message.error(
        `The ${icons.length > 1 ? 'Icons were' : 'Icon was'}  not removed, please try again`
      )
    }
  }

  return (
    <Modal
      closable={false}
      visible
      okButtonProps={{ danger: true }}
      onOk={handleDeleteIcon}
      onCancel={onCancel}
      okText="Delete"
      confirmLoading={deleting}
      title="Caution!"
    >
      <p>
        <ExclamationCircleOutlined className={styles.deleteModalIcon} />
        Are you sure you want to
        <span className={styles.deleteWord}>
          {` delete the ${
            icons.length === 1 ? `"${icons[0].name}"` : `${icons.length} selected icons`
          }`}
        </span>
        ?
      </p>
    </Modal>
  )
}

export default IconsRemoveModal
