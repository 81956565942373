import { Checkbox, message, Modal } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import useAxios from '../../../app/api/apiHook'
import { Family } from '../assetsSlice'
import handleError from '../../../app/api/handleError'

interface ExportFamilyAssetsModalProps {
  family: Family
  onClose(): void
}

const ExportFamilyAssetsModal: FunctionComponent<ExportFamilyAssetsModalProps> = ({
  family,
  onClose,
}) => {
  const [addTags, setAddTags] = useState(false)

  const [{ loading: exportingFamilyAssets }, exportFamilyAssets] = useAxios(
    {
      url: `/v4/families/${family.slug}/export-assets?addTags=${addTags}`,
      method: 'GET',
      responseType: 'blob',
    },
    { manual: true }
  )

  const handleExportFamilyAssets = async () => {
    try {
      const buffer = await exportFamilyAssets()

      const blob = new Blob([buffer.data])

      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href = url
      a.download = `${family.slug}.zip`
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      message.error(`Something went wrong when downloading the file: ${handleError(error)}`)
    }
  }

  return (
    <Modal
      closable={false}
      visible
      okButtonProps={{ danger: false, loading: exportingFamilyAssets }}
      onOk={handleExportFamilyAssets}
      onCancel={onClose}
      okText="Export assets"
      title={`Export assets for ${family.name} icons.`}
    >
      <Checkbox checked={addTags} onChange={() => setAddTags(!addTags)}>
        Add tags to file name.
      </Checkbox>
    </Modal>
  )
}

export default ExportFamilyAssetsModal
