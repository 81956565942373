import React, { FunctionComponent } from 'react'
import Assets from '../../features/assets/Assets'
import styles from './AssetsPage.module.scss'

const AssetsPage: FunctionComponent = () => (
  <div className={styles.root}>
    <Assets />
  </div>
)

export default AssetsPage
