import { Col, Row } from 'antd'
import React, { FunctionComponent } from 'react'
import styles from './UserDetailsTwoColumns.module.scss'

interface UserDetailsTwoColumnsProps {
  leftCol: React.ReactNode
  rightCol: React.ReactNode
}

const USerDetailsTwoColumns: FunctionComponent<UserDetailsTwoColumnsProps> = ({
  leftCol,
  rightCol,
}) => (
  <Row className={styles.root}>
    <Col className={`${styles.left} ${styles.space}`} span={12}>
      {leftCol}
    </Col>
    <Col className={`${styles.right} ${styles.space}`} span={12}>
      {rightCol}
    </Col>
  </Row>
)

export default USerDetailsTwoColumns
