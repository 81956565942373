import { Tooltip, Typography } from 'antd'
import React, { FunctionComponent } from 'react'

const { Text } = Typography

/**
 * This custom component is needed since Tooltip can't wrap 2 components properly
 */
const FixOnlyWhenStrokeWidthEnabled: FunctionComponent<{ strokeAllowed: boolean }> = ({
  strokeAllowed,
  children,
}) => (
  <Tooltip title="This fix is applied only when this icon's family has stroke width feature enabled.">
    <Text delete={!strokeAllowed}>{children}</Text>
  </Tooltip>
)

/**
 * Image Engine on the backend is the source of truth of these.
 */
const ChangesListFix: FunctionComponent<{ strokeAllowed: boolean }> = ({ strokeAllowed }) => (
  <ul>
    <li>
      <Text>It will remove all `desc` tags which are not needed</Text>
    </li>
    <li>
      <Text>
        It will merge multiple `style` tags into one. There is no reason why there should be
        multiple `style` tags.
      </Text>
    </li>
    <li>
      <Text>
        It will move the contents of the `style` tag into corresponding SVG elements as styles.
        Downside: increases the size of the SVG file. Upsides: - it ensures that the issue with
        duplicated class declarations is impossible. That issue broke images in the Affinity
        Designer. - it prevents issues which happened because of the presence of `style` tag. For
        instance, Vue doesn't support SVG's `style` tags.
      </Text>
    </li>
    <li>
      <Text>
        It will convert all styles of SVG into attributes. This allows our backend to remove these
        attributes easily. Also it helps users to change styles of our SVGs with CSS as SVG
        attributes have the lowest priority in CSS.
      </Text>
    </li>
    <li>
      <FixOnlyWhenStrokeWidthEnabled strokeAllowed={strokeAllowed}>
        It will remove `stroke-width` from all elements so that we can adjust `stroke-width` by
        adding it to the svg tag itself. Also it adds the extracted `stroke-width` to the svg tag so
        it can be used as default.
      </FixOnlyWhenStrokeWidthEnabled>
    </li>
    <li>
      <Text>
        It will remove width and height attributes - If viewbox attribute doesn't exists it adds it
      </Text>
    </li>
    <li>
      <Text>
        It will convert all shapes into paths so that we can scale an SVG by scaling paths and
        leaving width/height/viewBox intact. This is what Figma does when you paste an SVG into it.
      </Text>
    </li>
    <li>
      <Text>
        It will simplify paths by removing unnecessary modifications eg transforms so that it's
        easier to manipulate paths when resizing. This is what Figma does when you paste an SVG into
        it.
      </Text>
    </li>
    <li>
      <Text>
        It will collapse multiple transformations and optimize them so that it's easier to
        manipulate paths when resizing. This is what Figma does when you paste an SVG into it.
      </Text>
    </li>
    <li>
      <Text>
        It will move group attributes to the elements inside the group so that it's easier to
        manipulate paths when resizing. This is what Figma does when you paste an SVG into it.
      </Text>
    </li>
    <li>
      <Text>
        It will clean up numeric values so that they look better. Eg after some transformations we
        might get `0.0000000000000001` instead of `0`, this plugin fixes it.
      </Text>
    </li>
    <li>
      <Text>
        It will attempt to automatically resize the SVG's contents so that they're centered and fit
        the container as much as possible, kinda like what Figma does with its "Fit to content"
        feature. Note that here it will preserve the viewBox's width, but not height.
      </Text>
    </li>
    <li>
      <Text>Named colors or RGBA colors are converted to longhand hex colors(#rrgggbb).</Text>
    </li>
    <li>
      <Text>In case icon has no colors, fill="#000000" will be added to all paths.</Text>
    </li>
  </ul>
)

export default ChangesListFix
