import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { Form, Tooltip, message } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthenticationToken } from '../../app/auth/authentication'
import envConfig from '../../app/config'
import * as assetsSlice from '../../features/assets/assetsSlice'
import { Icon } from '../../features/assets/assetsSlice'
import styles from './IconUpload.module.scss'

interface IconUploadProps {
  iconHash: string
  svg: string
  familySlug: string
  categorySlug: string
  subcategorySlug: string
  subcategoryHash: string
  onUploadComplete(iconFromUpload: Icon): void
  onUploadFailed?(errorMessage: string): void
}

const IconUpload: FunctionComponent<IconUploadProps> = ({
  iconHash,
  svg: defaultSVG,
  familySlug,
  categorySlug,
  subcategorySlug,
  subcategoryHash,
  onUploadComplete,
  onUploadFailed,
}) => {
  const [uploading, setUploading] = useState(false)
  const [svg, setSVG] = useState(defaultSVG)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const dispatch = useDispatch()
  const family = useSelector(assetsSlice.selectFamily)
  const subcategory = useSelector(assetsSlice.selectSubcategory)
  const [uploadToken, setUploadToken] = useState<string>('')

  const handleUploadComplete = (info: UploadChangeParam) => {
    try {
      const newSVG = info.file.response.svg
      const fileListJustLastUpload = [info.fileList[info.fileList.length - 1]]
      setSVG(newSVG)
      setUploading(false)
      setFileList(fileListJustLastUpload)
      onUploadComplete(info.file.response)
      if (iconHash) {
        dispatch(assetsSlice.actions.updateIconOnList(info.file.response))
      } else {
        dispatch(assetsSlice.actions.addIconOnList(info.file.response))
      }
      dispatch(assetsSlice.actions.selectedIcon(info.file.response))

      if (family && subcategory) {
        dispatch(assetsSlice.generateTagsForIcons([info.file.response.hash], family.productType))
      }
    } catch (error) {
      message.error('Error processing the updloaded icon.')
      setUploading(false)
    }
  }

  const handleUploadFailed = (error: string) => {
    setUploading(false)
    if (onUploadFailed) onUploadFailed(error)
  }

  useEffect(() => {
    setSVG(defaultSVG)
  }, [defaultSVG])

  const normalizeFileListFormat = (fileUploadEvent: UploadChangeParam): UploadFile[] =>
    fileUploadEvent.fileList

  let content
  if (uploading) {
    content = (
      <div className={styles.uploadingIcon}>
        <LoadingOutlined />
        <p>Uploading icon</p>
      </div>
    )
  } else if (svg) {
    content = (
      <img
        alt="icon's svg"
        src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`}
        className={styles.image}
      />
    )
  } else {
    content = (
      <>
        <p className="ant-upload-drag-icon">
          <UploadOutlined width={18} />
        </p>
        <p className={styles.draggerText}>Click or drag file to this area to upload</p>
        <p className={styles.draggerTextSmall}>File type accepted : .svg</p>
      </>
    )
  }

  const getUploadUrl = async () => {
    const token = await getAuthenticationToken()

    if (token) {
      setUploadToken(token)
    }

    return `${
      envConfig.API_URL
    }/v4/icons/${familySlug}/${categorySlug}/${subcategorySlug}/upload?subcategoryHash=${subcategoryHash}${
      iconHash ? `&iconHash=${iconHash}` : ''
    }`
  }

  return (
    <Tooltip title="Click or drag file to this area to upload a new icon">
      <Form>
        <Form.Item
          valuePropName="fileList"
          getValueFromEvent={normalizeFileListFormat}
          name="fileList"
        >
          <Dragger
            name="icon"
            multiple={false}
            action={getUploadUrl}
            headers={{ Authorization: uploadToken }}
            onChange={(info) => {
              if (info.file.status === 'uploading') {
                setUploading(true)
              } else if (info.file.status === 'done') {
                handleUploadComplete(info)
              } else if (info.file.status === 'error' && onUploadFailed) {
                handleUploadFailed(info.file.response.messages.join(', '))
              }
            }}
            showUploadList={false}
            accept=".svg,image/svg+xml"
            listType="picture-card"
            fileList={fileList}
          >
            {content}
          </Dragger>
        </Form.Item>
      </Form>
    </Tooltip>
  )
}

export default IconUpload
