import { Form, FormInstance, InputNumber, Switch } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import AntFormItemExplain from '../../../../../components/AntFormItemExplain/AntFormItemExplain'
import DrawerSubtitle from '../../../../../components/DrawerSubtitle/DrawerSubtitle'
import { Family } from '../../../assetsSlice'

const FamilyFormRightColumn: FunctionComponent<{ form: FormInstance<Family> }> = ({ form }) => {
  const [showStrokeWidthInput, setShowStrokeWidthInput] = useState(
    form.getFieldValue('strokeAllowed')
  )

  const [showVisibleOnlyOnFreebiesTab, setShowVisibleOnlyOnFreebiesTab] = useState(
    form.getFieldValue('isFree')
  )

  return (
    <>
      <DrawerSubtitle text="Options" />
      <Form.Item
        label="Show to users"
        name="visible"
        valuePropName="checked"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label="Is family 'Free'?"
        name="isFree"
        valuePropName="checked"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Switch onChange={(value) => setShowVisibleOnlyOnFreebiesTab(value)} />
      </Form.Item>

      <Form.Item
        label="Is third party family?"
        name="isThirdParty"
        valuePropName="checked"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label={
          <div>
            Display in both premium and free lists?
            <br />
            <AntFormItemExplain
              type="text"
              message="If this option is active the family will appear in all lists in the app. e.g. a free family can appear in the premium families list."
            />
          </div>
        }
        name="displayInFreeAndPremium"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      {showVisibleOnlyOnFreebiesTab && (
        <Form.Item
          label="Should this family be visible just on Freebies tab?"
          name="visibleOnlyOnFreebiesTab"
          valuePropName="checked"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Switch />
        </Form.Item>
      )}

      <Form.Item
        label="Should only legacy users see this family?"
        name="onlyLegacyVisible"
        valuePropName="checked"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label="Should legacy users have premium access to this family?"
        name="legacyHavePremium"
        valuePropName="checked"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Switch />
      </Form.Item>

      <Form.Item label="Is family a 'Work in Progress'?" name="isBeta" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item
        label="Is family 'New'?"
        name="isNew"
        valuePropName="checked"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label={
          <div>
            Does family have only one color?
            <br />
            <AntFormItemExplain
              type="text"
              message="If this option is active along with 'Allow Stroke', outlined strokes option will be activated automatically"
            />
          </div>
        }
        name="oneColored"
        valuePropName="checked"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Switch />
      </Form.Item>

      <DrawerSubtitle text="Preferences" />

      <Form.Item
        label={
          <div>
            Sorting number
            <br />
            <AntFormItemExplain
              type="text"
              message="Help to sort families. 01 = first in the list"
            />
          </div>
        }
        name="order"
        rules={[
          {
            required: true,
            message: 'Please input a family order!',
          },
        ]}
      >
        <InputNumber min={1} max={999} />
      </Form.Item>

      <Form.Item
        label={
          <div>
            Lazy Loading Assets
            <br />
            <AntFormItemExplain
              type="text"
              message="Define how many assets are loaded by default"
            />
          </div>
        }
        name="defaultLazyLoadItems"
        rules={[
          {
            required: true,
            message: 'Please input a family order!',
          },
        ]}
      >
        <InputNumber min={1} max={999} />
      </Form.Item>

      <Form.Item
        label={
          <div>
            Preview Size - List
            <br />
            <AntFormItemExplain
              type="text"
              message="Size of asset preview in assets list (pixels)"
            />
          </div>
        }
        name="defaultSize"
        rules={[
          {
            required: true,
            message: 'Please input a preview list size!',
          },
        ]}
      >
        <InputNumber min={1} max={999} />
      </Form.Item>

      <Form.Item
        label={
          <div>
            Preview Size - Sidebar
            <br />{' '}
            <AntFormItemExplain
              type="text"
              message="Size of asset preview in the sidebar (pixels)"
            />
          </div>
        }
        name="defaultSidebarSize"
        rules={[
          {
            required: true,
            message: 'Please input a preview sidebar size!',
          },
        ]}
      >
        <InputNumber min={1} max={999} />
      </Form.Item>

      <Form.Item
        label={
          <div>
            Default Download Size
            <br />
            <AntFormItemExplain type="text" message="Default size of asset for download (pixels)" />
          </div>
        }
        name="defaultDownloadSize"
        rules={[
          {
            required: true,
            message: 'Please input a default download size!',
          },
        ]}
      >
        <InputNumber min={1} max={999} />
      </Form.Item>
      <Form.Item
        label={
          <div>
            Max Free Download Size
            <br />
            <AntFormItemExplain
              type="text"
              message="Max free size of asset for download (pixels)"
            />
          </div>
        }
        name="maxFreeSizeDownload"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <InputNumber min={1} max={999} />
      </Form.Item>
      <Form.Item
        label={
          <div>
            Has Global Colors
            <br />
            <AntFormItemExplain
              type="text"
              message="The option to change the colors for the entire family will appear in the family assets page"
            />
          </div>
        }
        name="hasGlobalColors"
        valuePropName="checked"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label={
          <div>
            Allow Stroke
            <br />
            <AntFormItemExplain
              type="text"
              message="Allow users to change stroke width. Note that after you enable this feature you might need to fix all this family's SVGs with our Fix/Restore tool so that this feature works as expected. If this option is active along with 'Does family have only one color?', outlined strokes option will be activated automatically"
            />
          </div>
        }
        name="strokeAllowed"
        valuePropName="checked"
      >
        <Switch onChange={(value) => setShowStrokeWidthInput(value)} />
      </Form.Item>
      {showStrokeWidthInput && (
        <Form.Item
          label={
            <div>
              Default stroke width
              <br />
              <AntFormItemExplain
                type="text"
                message="Sets the default stroke width for this family."
              />
            </div>
          }
          name="defaultStrokeWidth"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={0.5} max={4} step="0.25" defaultValue={1} />
        </Form.Item>
      )}
    </>
  )
}

export default FamilyFormRightColumn
