import { ArrowRightOutlined, DeleteOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { Footer } from 'antd/lib/layout/layout'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as assetsSlice from '../assetsSlice'
import styles from './AssetsFooter.module.scss'
import IconsRemoveModal from '../icons-remove-modal/IconsRemoveModal'
import IconsMoveModal from './icons-move-modal/IconsMoveModal'

const AssetsFooter: FunctionComponent = () => {
  const family = useSelector(assetsSlice.selectFamily)
  const category = useSelector(assetsSlice.selectCategory)
  const subcategory = useSelector(assetsSlice.selectSubcategory)
  const selectedIcons = useSelector(assetsSlice.selectSelectedIcons)
  const dispatch = useDispatch()

  const [isDeleteIconsModalVisible, setIsDeleteIconsModalVisible] = useState(false)
  const [isMoveIconsModalVisible, setIsMoveIconsModalVisible] = useState(false)

  return (
    <Footer className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.left}>
          <div className={styles.newCategoryButtonArea}>
            {family && (
              <Button
                className={styles.newButton}
                icon={<PlusOutlined />}
                onClick={() =>
                  dispatch(
                    assetsSlice.actions.toggleDrawer({
                      visible: true,
                      type: 'category',
                      data: null,
                    })
                  )
                }
              >
                New Category
              </Button>
            )}
          </div>
          <div className={styles.newSubcategoryButtonArea}>
            {category && (
              <Button
                className={styles.newButton}
                icon={<PlusOutlined />}
                onClick={() =>
                  dispatch(
                    assetsSlice.actions.toggleDrawer({
                      visible: true,
                      type: 'subcategory',
                      data: null,
                    })
                  )
                }
              >
                New Subcategory
              </Button>
            )}
          </div>
          <div className={styles.moveIconsButtonArea}>
            {selectedIcons.length > 0 && (
              <Button
                className={styles.newButton}
                icon={<ArrowRightOutlined />}
                onClick={() => setIsMoveIconsModalVisible(true)}
              >
                Move
              </Button>
            )}
          </div>
          <div className={styles.moveIconsButtonArea}>
            {selectedIcons.length > 0 && (
              <Button
                className={styles.newButton}
                icon={<DeleteOutlined />}
                onClick={() => setIsDeleteIconsModalVisible(true)}
              >
                Delete
              </Button>
            )}
          </div>
        </div>
        <div className={styles.right}>
          {subcategory && (
            <>
              <Button
                icon={<UploadOutlined />}
                onClick={() => {
                  dispatch(assetsSlice.actions.selectedIcon(null))
                  dispatch(
                    assetsSlice.actions.toggleDrawer({
                      visible: true,
                      type: 'multiple-icons',
                      data: null,
                    })
                  )
                }}
              >
                Upload Multiple Assets
              </Button>
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  dispatch(assetsSlice.actions.selectedIcon(null))
                  dispatch(
                    assetsSlice.actions.toggleDrawer({ visible: true, type: 'icon', data: null })
                  )
                }}
              >
                New Asset
              </Button>
            </>
          )}
        </div>
        {isMoveIconsModalVisible && (
          <IconsMoveModal
            icons={selectedIcons}
            onCancel={() => setIsMoveIconsModalVisible(false)}
            onSuccess={() => setIsMoveIconsModalVisible(false)}
          />
        )}
        {isDeleteIconsModalVisible && (
          <IconsRemoveModal
            icons={selectedIcons}
            onCancel={() => setIsDeleteIconsModalVisible(false)}
            onSuccess={() => setIsDeleteIconsModalVisible(false)}
          />
        )}
      </div>
    </Footer>
  )
}

export default AssetsFooter
