import React, { FunctionComponent } from 'react'
import Preferences from '../../features/preferences/Preferences'
import styles from './PreferencesPage.module.scss'

const PreferencesPage: FunctionComponent = () => (
  <div className={styles.root}>
    <Preferences />
  </div>
)

export default PreferencesPage
