import { InfoCircleOutlined, SendOutlined } from '@ant-design/icons'
import { Button, message, Popconfirm } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import useAxios from '../../../../../app/api/apiHook'
import handleError from '../../../../../app/api/handleError'

interface SendPasswordButtonProps {
  email: string
}
const SendPasswordButton: FunctionComponent<SendPasswordButtonProps> = ({ email }) => {
  const [isSendingEmail, setIsSendingEmail] = useState(false)

  const [, sendEmail] = useAxios(
    {
      url: '/v4/users/password',
      method: 'POST',
    },
    { manual: true }
  )

  const handleResetPassword = async () => {
    try {
      setIsSendingEmail(true)
      await sendEmail({
        data: { email },
      })
      setIsSendingEmail(false)
      message.success('Password sent successfully!')
    } catch (error) {
      setIsSendingEmail(false)
      const responseError = handleError(error)
      message.error(`Something went wrong: ${responseError}`)
    }
  }

  return (
    <Popconfirm
      title={<span>Send a reset password email to {email} ?</span>}
      okType="primary"
      icon={<InfoCircleOutlined />}
      okText="Yes, send email!"
      onConfirm={() => handleResetPassword()}
    >
      <Button type="link" icon={<SendOutlined />} loading={isSendingEmail}>
        Send Password Reset
      </Button>
    </Popconfirm>
  )
}

export default SendPasswordButton
