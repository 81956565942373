import { useDispatch, useSelector } from 'react-redux'
import { Button, Input } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import ReindexResultButton from './ReindexResultButton'
import { reIndexAssets, selectIsIndexing, setIsIndexing } from '../preferencesSlice'

const ReindexAllElasticButton: FunctionComponent = () => {
  const dispatch = useDispatch()
  const [indexName, setIndexName] = useState('')
  const isIndexing = useSelector(selectIsIndexing)

  return (
    <>
      <Input.Group compact>
        {!isIndexing && (
          <>
            <Input
              style={{ width: '250px' }}
              placeholder="New index name"
              onChange={(e) => setIndexName(e.target.value.toLowerCase())}
            />
            <Button
              title="Click here to reindex all assets on OpenSearch!"
              onClick={() => dispatch(reIndexAssets(indexName))}
              disabled={indexName.trim().length === 0}
            >
              Re-index all assets on OpenSearch
            </Button>
          </>
        )}{' '}
        <ReindexResultButton />{' '}
        {/* Adding this button as it's possible that it will keep loading forever in case the instance that the process is running terminates on AWS for some reason like a deploy at the same time */}
        {isIndexing && (
          <Button
            title="If for some reason it's loading forever without the logs updating, click here to force reindex again. This can happens in case there is a new deploy on AWS at the same time this is running."
            onClick={() => dispatch(setIsIndexing(false))}
          >
            Force re-index
          </Button>
        )}
      </Input.Group>
    </>
  )
}

export default ReindexAllElasticButton
