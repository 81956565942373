import React, { FunctionComponent } from 'react'
import { FeedbackMessageType } from '../../app/types'
import styles from './AntFormItemExplain.module.scss'

interface AntFormItemExplainErrorProps {
  message: string
  type: FeedbackMessageType
  className?: string
}

const AntFormItemExplain: FunctionComponent<AntFormItemExplainErrorProps> = ({
  message,
  type,
  className,
}) =>
  message ? (
    <div className={`ant-form-item-explain ${styles[type]} ${className}`}>
      <div role="alert">{message}</div>
    </div>
  ) : null

export default AntFormItemExplain
