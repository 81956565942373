/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import { selectIsLoggedIn } from '../auth/authSlice'
import LayoutAdmin from '../../components/LayoutAdmin/LayoutAdmin'

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps>
}

const PrivateRoute: React.FC<ProtectedRouteProps> = ({
  component: RouteComponent,
  ...rest
}: ProtectedRouteProps) => {
  const loggedIn = useSelector(selectIsLoggedIn)

  return (
    <LayoutAdmin>
      <Route
        {...rest}
        render={(routeProps) =>
          loggedIn ? <RouteComponent {...routeProps} /> : <Redirect to="/" />
        }
      />
    </LayoutAdmin>
  )
}

export default PrivateRoute
