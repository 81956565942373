import React, { FunctionComponent } from 'react'
import UserDetails from '../../../features/users/user-details/UserDetails'
import styles from './UserDetailsPage.module.scss'

const UserDetailsPage: FunctionComponent = () => (
  <div className={styles.root}>
    <UserDetails />
  </div>
)

export default UserDetailsPage
